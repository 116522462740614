<template>
  <div class="boxtotal">
    <div class="nav-box">
      <div class="logobox">
        <img class="logo" :src="navList.logo" alt="" />
        <i class="i1"></i>
        <span>{{ navList.name }}</span>
      </div>
      <div class="huabox">
        <!-- 呼入 -->
        <div class="conversation" v-if="emicreg">
          <div class="tonghuazhong" v-if="emicreg">
            <img
              @click="bodahaoma"
              class="tongImg bohaoma"
              @mouseenter="bodial"
              @mouseleave="chudial"
              src="./assets/img/index/bohao.png"
              alt=""
              v-show="bodahao"
            />

            <img
              class="tongImg bohaoma"
              v-show="jinbo"
              src="./assets/img/index/jinbo.png"
              alt=""
            />
            <div class="dial" v-show="yindial">开始拨号</div>
          </div>

          <div class="tonghuazhong" @click="hu">
            <img
              class="tongImg bohaoma"
              @mouseenter="jilu"
              @mouseleave="chujilu"
              src="./assets/img/index/jilu.png"
              alt=""
            />
            <!-- <div class="tongTit">呼叫记录</div> -->
            <div class="dial" v-show="yinjilui">呼叫记录</div>
          </div>

          <div class="tonghuazhong" v-if="daildetail.type != ''">
            <!--<img class="tongImg" src="./assets/img/index/huahead.png" alt="" />
            <img class="tongImg" src="./assets/img/index/huahead2.png" alt="" />-->
            <div class="tongTit">
              <div class="hujiao" @click="hu">{{ daildetail.type }}</div>
              <div class="hujiao jianju">{{ daildetail.number }}</div>
            </div>
            <div class="tongTit" v-if="nowtimeshow" id="tonghua"></div>
          </div>

          <div class="tonghuazhong jianju">
            <div class="dianhua" v-if="jieting" @click="answer">
              <img class="tongImg" src="./assets/img/index/jie.png" alt="" />
              <div class="tongTit">接听</div>
            </div>
            <div class="dianhua jianju" v-if="guaduan" @click="hangup">
              <img class="tongImg" src="./assets/img/index/gua.png" alt="" />
              <div class="tongTit">挂断</div>
            </div>
          </div>

          <div class="tonghuazhong jianju" v-if="zhuanj">
            <div class="dianhua" @click="zhuan">
              <img
                class="tongImg"
                src="./assets/img/index/zhuanjie.png"
                alt=""
              />
              <div class="tongTit">转接</div>
            </div>
          </div>

          <div class="tonghuazhong jianju" v-if="false">
            <div class="dianhua" @click="san">
              <img
                class="tongImg"
                src="./assets/img/index/sanfang.png"
                alt=""
              />
              <div class="tongTit">三方通话</div>
            </div>
          </div>

          <div class="tonghuazhong jianju" v-if="guaduan">
            <div class="dianhua" @click="hold" v-if="jingyin">
              <img
                class="tongImg"
                src="./assets/img/index/jingyin.png"
                alt=""
              />
              <div class="tongTit">静&#12288;音</div>
            </div>
            <div class="dianhua" @click="unhold" v-if="yijingyin">
              <img
                class="tongImg"
                src="./assets/img/index/yijingyin.png"
                alt=""
              />
              <div class="tongTit jingyin">已静音</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 拨号 -->
      <div class="bohaoBox" v-if="bohaopan">
        <div class="bohao">
          <div class="bohaoNav">
            <div class="bohaoTit">拨号</div>
            <img
              @click="guanhao"
              class="bohaoImg"
              src="./assets/img/index/cha.png"
              alt=""
            />
          </div>

          <div id="online" class="callDial" align="center">
            <div class="ui-field-contain" style="margin: 0">
              <input type="text" id="dest_phone" v-model="dialphone" />
              <div id="incomingCall">
                <span></span>
                <span id="vertoCaller_id"></span>
                <span id="vertoDest_number"></span>
              </div>
              <div id="dialpad">
                <button
                  v-for="(num, index) in nums"
                  :key="index"
                  :style="{ marginRight: (index + 1) % 3 === 0 ? '0' : '' }"
                  @click="addNumber(num)"
                >
                  {{ num }}
                </button>
              </div>
              <div class="btn_group">
                <button
                  data-inline="true"
                  id="dialBtn"
                  class="phonebtn"
                  v-show="newboda"
                  @click="doCall(dialphone)"
                >
                  拨打
                </button>
                <button
                  data-inline="true"
                  id="againBtn"
                  class="phonebtn"
                  v-show="chongbo"
                  @click="doCall(dialphone)"
                >
                  重播
                </button>
                <button
                  data-inline="true"
                  id="answerBtn"
                  class="phonebtn"
                  v-show="newjieting"
                  @click="vertoAnswer"
                >
                  接听
                </button>
                <button
                  data-inline="true"
                  id="hangupBtn"
                  class="phonebtn"
                  v-show="newguaduan"
                  @click="vertoDecline"
                >
                  挂断
                </button>
                <button
                  data-inline="true"
                  id="clearBtn"
                  class="phonebtn"
                  @click="clearNumber"
                >
                  清除
                </button>
              </div>
              <input type="hidden" id="reDest_phone" />
            </div>
          </div>
        </div>
      </div>
      <!-- 三方通话 -->
      <div class="sanfangBox" v-if="false">
        <div class="sanfang">
          <div class="bohaoNav">
            <div class="bohaoTit">三方通话</div>
            <img
              @click="guanhao"
              class="bohaoImg"
              src="./assets/img/index/cha.png"
              alt=""
            />
          </div>

          <div class="sanfangMain">
            <table class="sanfangTable" border="0" cellspacing="0">
              <thead class="sanfangthead">
                <th>姓名</th>
                <th>电话</th>
                <th>操作</th>
              </thead>
              <tbody>
                <tr
                  class="sanfangTr"
                  v-for="(item, index) in otherEmic"
                  :key="index"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.extn }}</td>
                  <td class="yaoqing" @click="olathree(item.extn)">邀请</td>
                </tr>
                <tr class="sanfangTr">
                  <td>其他</td>
                  <td>
                    <input
                      type="text"
                      class="sanfangnum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      placeholder="请输入第三方号码"
                      v-model="sfnumber"
                    />
                  </td>
                  <td class="yaoqing" @click="olathree2()">邀请</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- 等待列表 -->
      <div class="sanfangBox" v-if="dengdai">
        <div class="sanfang">
          <div class="bohaoNav">
            <div class="bohaoTit">等待电话列表</div>
            <img
              @click="guanhao"
              class="bohaoImg"
              src="./assets/img/index/cha.png"
              alt=""
            />
          </div>

          <div class="sanfangMain">
            <table class="sanfangTable" border="0" cellspacing="0">
              <thead class="sanfangthead">
                <th>姓名</th>
                <th>电话</th>
                <th>操作</th>
              </thead>
              <tbody>
                <tr class="sanfangTr">
                  <td>当当当</td>
                  <td>13746454412</td>
                  <td class="yaoqing">接入</td>
                </tr>
                <tr class="sanfangTr">
                  <td>当当当</td>
                  <td>13746454412</td>
                  <td class="yaoqing">接入</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- 转接 -->
      <div class="sanfangBox" v-if="zhuanjie">
        <div class="sanfang">
          <div class="bohaoNav">
            <div class="bohaoTit">转接</div>
            <img
              @click="guanhao"
              class="bohaoImg"
              src="./assets/img/index/cha.png"
              alt=""
            />
          </div>

          <div class="sanfangMain" v-if="zhuanj">
            <table class="sanfangTable" border="0" cellspacing="0">
              <thead class="sanfangthead">
                <th>姓名</th>
                <th>电话</th>
                <th>操作</th>
              </thead>
              <tbody>
                <tr
                  class="sanfangTr"
                  v-for="(item, index) in otherEmic"
                  :key="index"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.extn }}</td>
                  <td class="yaoqing" @click="olatransfer(item.extn)">转接</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- 呼叫记录 -->
      <div class="sanfangBox" v-if="hujiao">
        <div class="sanfang">
          <div class="bohaoNav">
            <div class="bohaoTit">呼叫记录</div>
            <img
              @click="guanhao"
              class="bohaoImg"
              src="./assets/img/index/cha.png"
              alt=""
            />
          </div>

          <div class="sanfangMain">
            <table class="sanfangTable" border="0" cellspacing="0">
              <thead class="sanfangthead">
                <th>姓名</th>
                <th>电话</th>
                <th>时间</th>
              </thead>
              <tbody>
                <tr
                  class="sanfangTr"
                  v-for="(item, index) in calllog"
                  :key="index"
                >
                  <td>{{ item.name }}</td>
                  <td class="bodahunum" @click="hunum(item.number)">
                    {{ item.number }}
                  </td>
                  <td>{{ item.time }} {{ item.type }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="navright">
        <div class="qianglocal" v-if="leftList.hasWork">
          <img
            @click="snatchbtn"
            class="qiangimg"
            src="./assets/img/cd/qiang.png"
            alt=""
          />

          <span class="qiangmessage" v-if="shareNumber > 0">{{
            shareNumber
          }}</span>
        </div>

        <div class="local" @click.stop v-if="leftList.hasWork && othergid > 0">
          <!-- 铃铛 -->
          <img
            class="inform"
            @mouseenter="lings"
            @mouseleave="dangs"
            @click.stop="change"
            src="./assets/img/cd/qiehuan.png"
            alt=""
            style="margin-right: 20px; margin-left: 15px"
          />
          <span class="message1" v-if="otherNum > 0">{{ otherNum }}</span>

          <!-- 鼠标悬浮铃铛显示 -->
          <div class="bell" v-show="other">切换账号</div>
        </div>
        <!-- 铃铛 -->
        <div class="local" @click.stop>
          <!-- 铃铛 -->
          <img
            v-show="navList.hasMessage"
            class="inform"
            @mouseenter="ling"
            @mouseleave="dang"
            @click.stop="message"
            src="./assets/img/cd/xiaoxi.png"
            alt=""
          />
          <span class="message" v-if="messageNum > 0">{{ messageNum }}</span>

          <!-- 鼠标悬浮铃铛显示 -->
          <div class="bell" v-show="word">消息提示</div>

          <!-- 鼠标点击显示 -->
          <div class="advices" v-if="mess">
            <div class="advices-box">
              <a
                class="ding"
                @click="changemessage(2)"
                :class="{ action: arr === 2 }"
              >
                订单消息
              </a>

              <a
                class="ding"
                @click="changemessage(3)"
                :class="{ action: arr === 3 }"
                >取消申请</a
              >

              <a
                class="ding"
                @click="changemessage(4)"
                :class="{ action: arr === 4 }"
                >接口信息</a
              >

              <a
                class="ding"
                @click="changemessage(5)"
                :class="{ action: arr === 5 }"
                >回收订单</a
              >
              <a
                class="ding"
                v-if="leftList.hasEF"
                @click="changemessage(6)"
                :class="{ action: arr === 6 }"
                >电子围栏</a
              >
              <a
                class="ding"
                v-if="leftList.hasBlack"
                @click="changemessage(7)"
                :class="{ action: arr === 7 }"
                >黑名单</a
              >
            </div>
            <!-- <div class="main" v-if="arr == 1">全部</div> -->
            <!-- 订单消息 -->
            <div class="dingMain" v-if="arr == 2">
              <!-- 触底加载，分页 -->
              <!-- 消息列表 -->
              <ul
                v-infinite-scroll="load"
                class="infinite-list"
                style="overflow: auto"
                infinite-scroll-disabled="noMore"
              >
                <li
                  v-for="item in messageList"
                  :key="item"
                  class="infinite-list-item"
                >
                  <div
                    v-if="item.isRead == 0"
                    @click="shouli(item.id, item.rescueId, item.behavior)"
                  >
                    <div class="sp-title1" v-if="item.isRead == 0">
                      {{ item.content }}
                    </div>
                    <p class="p-title">{{ item.from }}</p>
                  </div>

                  <div v-if="item.isRead == 1" @click="detail(item.rescueId)">
                    <div class="sp-title1" v-if="item.isRead == 1">
                      {{ item.content }}
                    </div>

                    <p class="p-title">{{ item.from }}</p>
                  </div>
                  <div class="case" v-if="item.isRead == 1">已受理</div>
                </li>
              </ul>
            </div>
            <!-- 取消申請 -->
            <div class="cancelMain" v-if="arr == 3">
              <ul
                class="infinite-list"
                v-infinite-scroll="load"
                style="overflow: auto"
                infinite-scroll-disabled="noMore"
              >
                <li
                  @click="apply(item.id)"
                  v-for="item in messageList"
                  :key="item"
                  class="infinite-list-item cancelList"
                >
                  <div class="cancelBox">
                    <div class="cancenTit">{{ item.content }}</div>
                    <p class="cancelId">{{ item.from }}</p>
                  </div>

                  <div class="cancelTai" v-if="item.isOp == 1">已处理</div>
                  <div class="cancelTai2" v-if="item.isOp == 0">未处理</div>
                </li>
              </ul>
            </div>

            <!-- 接口信息 -->
            <div class="jieMain" v-if="arr == 4">
              <ul
                class="infinite-list"
                v-infinite-scroll="load"
                style="overflow: auto"
                infinite-scroll-disabled="noMore"
              >
                <li
                  @click="apilook(item.id, item.rescueId)"
                  v-for="item in messageList"
                  :key="item"
                  class="infinite-list-item apiList"
                >
                  <div class="cancelBox">
                    <div class="cancenTit">{{ item.content }}</div>
                    <p class="cancelId">{{ item.from }}</p>
                  </div>

                  <div class="cancelTai" v-if="item.isOp == 1">已处理</div>
                  <div class="cancelTai2" v-if="item.isOp == 0">未处理</div>
                </li>
              </ul>
            </div>

            <!-- 回收订单 -->
            <div class="jieMain" v-if="arr == 5">
              <ul
                class="infinite-list"
                v-infinite-scroll="load"
                style="overflow: auto"
                infinite-scroll-disabled="noMore"
              >
                <li
                  @click="
                    recovery(item.id, item.rescueId, item.behavior, item.isOp)
                  "
                  v-for="item in messageList"
                  :key="item"
                  class="infinite-list-item apiList"
                >
                  <div class="cancelBox">
                    <div class="cancenTit">{{ item.content }}</div>
                    <p class="cancelId">{{ item.from }}</p>
                  </div>

                  <div
                    class="cancelTai"
                    v-if="item.isOp == 1 && item.behavior == 99"
                  >
                    已改
                  </div>
                  <div
                    class="cancelTai2"
                    v-if="item.isOp == 0 && item.behavior == 99"
                  >
                    改派
                  </div>
                  <div
                    class="cancelTai"
                    v-if="item.isOp == 1 && item.behavior == 102"
                  >
                    已接
                  </div>
                  <div
                    class="cancelTai2"
                    v-if="item.isOp == 0 && item.behavior == 102"
                  >
                    接管
                  </div>
                </li>
              </ul>
            </div>
            <!-- 电子围栏 -->
            <div class="jieMain" v-if="arr == 6">
              <ul
                class="infinite-list"
                v-infinite-scroll="load"
                style="overflow: auto"
                infinite-scroll-disabled="noMore"
              >
                <li
                  @click="enclosurebtn(item.id, item.rescueId)"
                  v-for="item in messageList"
                  :key="item"
                  class="infinite-list-item apiList"
                >
                  <div class="cancelBox">
                    <div class="cancenTit">{{ item.from }}</div>
                    <p class="cancelId">{{ item.content }}</p>
                  </div>

                  <div class="cancelTai" v-if="item.isOp == 1">已处理</div>
                  <div class="cancelTai2" v-if="item.isOp == 0">未处理</div>
                </li>
              </ul>
            </div>
            <!-- 新加新加 -->
            <div class="jieMain" v-if="arr == 7">
              <ul
                class="infinite-list"
                v-infinite-scroll="load"
                style="overflow: auto"
                infinite-scroll-disabled="noMore"
              >
                <li
                  @click="bbll(item.id, item.rescueId)"
                  v-for="item in messageList"
                  :key="item"
                  class="infinite-list-item apiList"
                >
                  <div class="cancelBox">
                    <div class="cancenTit">{{ item.content }}</div>
                    <p class="cancelId">{{ item.from }}</p>
                  </div>

                  <div class="cancelTai" v-if="item.isOp == 1">已读</div>
                  <div class="cancelTai2" v-if="item.isOp == 0">未读</div>
                </li>
              </ul>
            </div>

            <div class="advices-bootm">
              <div>{{ tishi }}</div>
            </div>
          </div>
        </div>
        <!-- 创建订单 -->
        <div class="local">
          <img
            v-show="navList.hasCreate"
            class="order"
            @click.stop="set"
            @mouseenter="ding"
            @mouseleave="dan"
            src="./assets/img/cd/fadan.png"
            alt=""
          />
          <!-- 鼠标悬浮显示提示文字 -->
          <div class="orderHint" v-show="ti">创建订单</div>
        </div>

        <!-- 微信 -->
        <div class="local">
          <img
            class="weiXin"
            @mouseenter="mouseenterFun"
            @mouseleave="mouseleaveFun"
            src="./assets/img/cd/weixin.png"
            alt=""
          />
          <!-- 鼠标移入显示  -->
          <div class="erMa" v-show="isshow">
            <img class="ma" src="https://s.nituowola.com/img/gzh.jpg" alt="" />
            <p class="title">扫码关注公众号</p>
            <i class="line"></i>
            <img class="ma" src="https://s.nituowola.com/img/xcx.jpg" alt="" />
            <p class="title">扫码体验小程序</p>
          </div>
        </div>
        <!-- 个人中心 -->
        <div class="local">
          <!-- 用户头像 -->
          <img
            class="head-img1"
            @mouseenter="ntries"
            @mouseleave="people"
            @click.stop="head"
            src="./assets/img/cd/wode.png"
            alt=""
          />

          <!-- 悬浮显示内容 -->
          <div class="nom" v-show="can">个人中心</div>

          <!-- 点击显示个人中心 -->
          <div class="head-display" v-if="isto">
            <div class="display-nav">
              <div class="head-box">
                <img
                  class="head-img"
                  src="./assets/img/index/head.png"
                  alt=""
                />
              </div>
              <p class="head-p">{{ navList.username }}</p>
            </div>
            <div class="display-main">
              <div @click.stop="perr" class="main1">
                <img
                  class="display-img"
                  src="./assets/img/slices7/personalcenter-icon-personal(1).png"
                  alt=""
                />
                <div class="display-title">个人中心</div>
              </div>
              <div @click.stop="appr" class="main1" v-if="ismain">
                <img
                  class="display-img"
                  src="./assets/img/slices7/personalcenter-icon-certification(1).png"
                  alt=""
                />
                <div class="display-title">账号认证</div>
              </div>
              <div class="main1" v-if="ismain">
                <img
                  class="display-img"
                  src="./assets/img/slices7/personalcenter-icon-help(1).png"
                  alt=""
                />
                <div class="display-title">帮助中心</div>
              </div>
              <div @click.stop="feedr" class="main1" v-if="ismain">
                <img
                  class="display-img"
                  src="./assets/img/slices7/personalcenter-icon-problem(1).png"
                  alt=""
                />
                <div class="display-title">问题反馈</div>
              </div>
            </div>
            <div class="display-bottom">
              <img
                class="display-img tui"
                src="./assets/img/slices7/personalcenter-icon-exit(1).png"
                alt=""
              />
              <div class="display-title tuichu" @click="logout">安全退出</div>
            </div>
          </div>
        </div>

        <!-- 云呼叫 -->
        <div class="local">
          <img
            class="emic"
            v-show="hasemic"
            @mouseenter="emiconfun"
            @mouseleave="emicofffun"
            @click.stop="emicc"
            src="./assets/img/index/emic.png"
            alt=""
          />

          <div class="emicshow" v-show="emicti">云呼叫</div>

          <div @click.stop>
            <div class="emiccontent" v-show="emiccontent">
              <div class="emictitle">云呼叫</div>
              <div class="emicbody">
                <div class="login" v-show="emicreg == ''">
                  <div class="un">
                    <div class="x">
                      <b style="color: red; margin-right: 5px">*</b>分机号：
                    </div>
                    <div class="y">
                      <input
                        type="text"
                        class="y1"
                        placeholder="请输入分机号"
                        v-model="un"
                      />
                    </div>
                  </div>
                  <div class="pwd">
                    <div class="x">
                      <b style="color: red; margin-right: 5px">*</b
                      >密&#12288;码：
                    </div>
                    <div class="y">
                      <input
                        type="password"
                        class="y1"
                        placeholder="请输入密码"
                        v-model="pwd"
                      />
                    </div>
                  </div>
                  <div class="pwd">
                    <div class="x">
                      <b style="color: red; margin-right: 5px">*</b
                      >模&#12288;式：
                    </div>
                    <div class="y">
                      <el-select
                        filterable
                        v-model="emicmode"
                        placeholder="通话模式"
                        style="width: 183px; text-align: center"
                      >
                        <el-option
                          v-for="item1 in emicmodelist"
                          :key="item1.value"
                          :value="item1.value"
                          :label="item1.name"
                        >
                          {{ item1.name }}
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="sub">
                    <button class="emicsub" @click="websocket_connect">
                      登录</button
                    ><button class="emiccancel" @click="closeemic">取消</button>
                  </div>
                </div>
                <div class="nologin" v-show="emicreg != ''">
                  <div class="un">
                    <div class="x">分机号：</div>
                    <div class="y" style="background: #f5f5f5">
                      <input
                        type="text"
                        class="y1"
                        placeholder="请输入分机号"
                        v-model="un"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="pwd">
                    <div class="x">密&#12288;码：</div>
                    <div class="y" style="background: #f5f5f5">
                      <input
                        type="password"
                        class="y1"
                        placeholder="请输入密码"
                        v-model="pwd"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="pwd">
                    <div class="x">模&#12288;式：</div>
                    <div class="y">
                      <el-select
                        filterable
                        v-model="emicmode"
                        placeholder="通话模式"
                        style="width: 183px; text-align: center"
                      >
                        <el-option
                          v-for="item1 in emicmodelist"
                          :key="item1.value"
                          :value="item1.value"
                          :label="item1.name"
                          disabled
                        >
                          {{ item1.name }}
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="sub">
                    <button class="emicout" @click="olalogout">退出</button
                    ><button class="emiccancel" @click="closeemic">取消</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <found v-if="isset" @ovfound="ovfound"></found>
    <personal :isper="per"></personal>
    <approve :isapp="app"></approve>
    <feedback :isfeed="feed"></feedback>
    <part :isXin="xinxi" :id="rescueId"></part>
    <apply :isapply="cancel" :applyid="applyidd"></apply>
    <ding-gai :iswei="accept" :weishouliId="weishouliId"></ding-gai>
    <snatch v-if="yinsnatch" @guansnatch="guansnatch"></snatch>
    <enclosure
      v-if="yinenclosure"
      @guanenclosure="guanenclosure"
      :warnid="warningid"
    ></enclosure>
    <amend :isame="isames" :tips="tips"></amend>

    <audio controls="controls" hidden :src="src" ref="audio"></audio>
    <audio controls="controls" hidden :src="src1" ref="audio1"></audio>
    <audio controls="controls" hidden :src="src2" ref="audio2"></audio>
    <audio controls="controls" hidden :src="src3" ref="audio3"></audio>
    <audio controls="controls" hidden :src="src4" ref="audio4"></audio>
    <audio controls="controls" hidden :src="src5" ref="audio5"></audio>
    <audio controls="controls" hidden :src="src6" ref="audio6"></audio>
    <audio controls="controls" id="webcam" hidden autoplay="autoplay"></audio>
    <div class="main-box">
      <!-- 左边列表 -->
      <div class="left-box">
        <ul>
          <router-link to="/">
            <li
              v-show="leftList.hasWork"
              @click="addFun(1)"
              :class="{ active: add === 1 }"
            >
              <img class="li-img1" :src="img" alt="" ref="imgChange1" />
              <span class="s1">工作台</span>
            </li>
          </router-link>
          <router-link to="/Order">
            <li
              v-show="leftList.hasOrder && leftList.ismain"
              @click="addFun(2)"
              :class="{ active: add === 2 }"
            >
              <img
                class="li-img2"
                src="./assets/img/cd/ding.png"
                alt=""
                ref="imgChange2"
              />
              <span class="s1">订单</span>
            </li>
          </router-link>
          <router-link to="/OrderG">
            <li
              v-show="leftList.hasOrder && leftList.ismain == false"
              @click="addFun(3)"
              :class="{ active: add === 3 }"
            >
              <img
                class="li-img3"
                src="./assets/img/cd/ding.png"
                alt=""
                ref="imgChange3"
              />
              <span class="s1">订单</span>
            </li>
          </router-link>
          <router-link to="/Business">
            <li
              v-show="leftList.hasPartner"
              @click="addFun(4)"
              :class="{ active: add === 4 }"
            >
              <img
                class="li-img4"
                src="./assets/img/cd/join.png"
                alt=""
                ref="imgChange4"
              />
              <span class="s1">合作</span>
            </li>
          </router-link>
          <router-link to="/GroupCompany">
            <li
              v-show="leftList.isgroup"
              @click="addFun(5)"
              :class="{ active: add === 5 }"
            >
              <img
                class="li-img5"
                src="./assets/img/cd/join.png"
                alt=""
                ref="imgChange5"
              />
              <span class="s1">子公司</span>
            </li>
          </router-link>
          <router-link to="/Vehicle">
            <li
              v-show="leftList.hasTech"
              @click="addFun(6)"
              :class="{ active: add === 6 }"
            >
              <img
                class="li-img6"
                src="./assets/img/cd/car.png"
                alt=""
                ref="imgChange6"
              />
              <span class="s1">车辆</span>
            </li>
          </router-link>
          <router-link to="/User">
            <li
              v-show="leftList.hasUser"
              @click="addFun(7)"
              :class="{ active: add === 7 }"
            >
              <img
                class="li-img7"
                src="./assets/img/cd/per.png"
                alt=""
                ref="imgChange7"
              />
              <span class="s1">人员</span>
            </li>
          </router-link>
          <router-link to="/Finance">
            <li
              v-show="
                leftList.hasFinance &&
                leftList.ismain == true &&
                leftList.isNew == false
              "
              @click="addFun(8)"
              :class="{ active: add === 8 }"
            >
              <img
                class="li-img8"
                src="./assets/img/cd/mony.png"
                alt=""
                ref="imgChange8"
              />
              <span class="s1">财务</span>
            </li>
          </router-link>
          <router-link to="/FinanceNew">
            <li
              v-show="
                (leftList.hasFinance || leftList.saleFinance) &&
                leftList.ismain == true &&
                leftList.isNew == true
              "
              @click="addFun(8)"
              :class="{ active: add === 8 }"
            >
              <img
                class="li-img8"
                src="./assets/img/cd/mony.png"
                alt=""
                ref="imgChange8"
              />
              <span class="s1">财务</span>
            </li>
          </router-link>
          <router-link to="/FinanceG">
            <li
              v-show="leftList.hasFinance && leftList.ismain == false"
              @click="addFun(8)"
              :class="{ active: add === 8 }"
            >
              <img
                class="li-img8"
                src="./assets/img/cd/mony.png"
                alt=""
                ref="imgChange8"
              />
              <span class="s1">财务</span>
            </li>
          </router-link>
          <router-link to="/Commission">
            <li
              v-show="leftList.isticheng"
              @click="addFun(9)"
              :class="{ active: add === 9 }"
            >
              <img
                class="li-img9"
                src="./assets/img/cd/ticheng.png"
                alt=""
                ref="imgChange9"
              />
              <span class="s1">提成</span>
            </li>
          </router-link>
          <router-link to="/Bill">
            <li
              v-show="leftList.isbill"
              @click="addFun(15)"
              :class="{ active: add === 15 }"
            >
              <img
                class="li-img15"
                src="./assets/img/cd/bill.png"
                alt=""
                ref="imgChange15"
              />
              <span class="s1">平台账单</span>
            </li>
          </router-link>
          <router-link to="/Maps">
            <li
              v-show="leftList.hasMaps"
              @click="addFun(10)"
              :class="{ active: add === 10 }"
            >
              <img
                class="li-img10"
                src="./assets/img/cd/map1.png"
                alt=""
                ref="imgChange10"
              />
              <span class="s1">地图</span>
            </li>
          </router-link>

          <router-link to="/Statistics">
            <li
              v-show="leftList.hasTab"
              @click="addFun(11)"
              :class="{ active: add === 11 }"
            >
              <img
                class="li-img11"
                src="./assets/img/cd/shu.png"
                alt=""
                ref="imgChange11"
              />
              <span class="s1">数据</span>
            </li>
          </router-link>

          <a
            :href="
              'https://www-admin.nituowola.com/?token=' +
              tokenid +
              '&$url=/car/car/user-scheduling'
            "
            target="_blank"
          >
            <li
              @click="addFun(12)"
              v-show="leftList.istechcommission"
              :class="{ active: add === 12 }"
              class="leftSix xuanweb"
            >
              <img
                class="li-img12"
                src="./assets/img/cd/manage.png"
                alt=""
                ref="imgChange12"
              />
              <span class="s1">管理</span>
            </li>
          </a>

          <router-link to="/set" class="set">
            <li
              @click="addFun(13)"
              :class="{ active: add === 13 }"
              v-show="leftList.hasSettting"
            >
              <img
                class="li-img13"
                src="./assets/img/cd/set.png"
                alt=""
                ref="imgChange13"
              />
              <span class="s1">设置</span>
            </li>
          </router-link>
        </ul>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Approve from "./components/approve.vue";
import Feedback from "./components/feedback.vue";
import found from "./components/found.vue";
import Personal from "./components/personal.vue";
import Cookies from "js-cookie";
import { sendSock, createWebSocket, closeSockS } from "./api/websock.js";
import part from "./components/part.vue";
import dingGai from "./components/dingGai.vue";
import match from "./components/match.vue";
import snatch from "./components/Snatch.vue";
import enclosure from "./components/enclosure.vue";
import amend from "./components/amend.vue";
import * as ola from "./api/olawebsock.js";
import * as vt from "./api/verto.js";
import { set } from "vue";

// 引入发送请求方法
import * as api from "./api/index";
import Apply from "./components/apply.vue";
import Watermark from "./common/watermark";

import ueSoftPhone from "ue-softphone-sdk";

let nowtime = 0;
let nowtimer = "";
export default {
  components: {
    found,
    Personal,
    Approve,
    Feedback,
    Apply,
    part,
    dingGai,
    match,
    snatch,
    enclosure,
    amend,
  },

  data() {
    return {
      isshow: false,
      mess: false,
      isto: false,
      ti: false,
      word: false,
      can: false,
      xinxi: false,
      jinbo: false,
      yindial: false,
      yinjilui: false,
      bodahao: true,
      jinbo: false,
      timerApp: null,
      isset: false,
      per: 0,
      app: 0,
      feed: 0,
      cancel: 0,
      add: 1,
      flag: -1,
      flag2: -1,
      arr: 2,
      save: 2,
      accept: 0,
      hasemic: false,
      ismain: true,
      ina: false,
      navList: [], //顶部导航数据
      leftList: [], //左侧导航数据
      // query: [],
      messageNum: 0,
      shareNumber: 0,
      messageList: [],
      applyidd: "", //取消申请rescueid
      i: 1,
      noMore: false, // 控制停止触底加载
      tishi: "滚动查看更多",
      img: require("./assets/img/cd/work.png"),
      imgs: require("./assets/img/cd/work1.png"),
      oldImgs: [
        { url: require("./assets/img/cd/work.png") },
        { url: require("./assets/img/cd/ding.png") },
        { url: require("./assets/img/cd/ding.png") },
        { url: require("./assets/img/cd/join.png") },
        { url: require("./assets/img/cd/join.png") },
        { url: require("./assets/img/cd/car.png") },
        { url: require("./assets/img/cd/per.png") },
        { url: require("./assets/img/cd/mony.png") },
        { url: require("./assets/img/cd/ticheng.png") },
        { url: require("./assets/img/cd/map1.png") },
        { url: require("./assets/img/cd/shu.png") },
        { url: require("./assets/img/cd/manage.png") },
        { url: require("./assets/img/cd/set.png") },
        { url: require("./assets/img/cd/bill.png") },
        { url: require("./assets/img/cd/bill.png") },
      ],
      imgs: [
        { url: require("./assets/img/cd/work1.png") },
        { url: require("./assets/img/cd/ding2.png") },
        { url: require("./assets/img/cd/ding2.png") },
        { url: require("./assets/img/cd/join2.png") },
        { url: require("./assets/img/cd/join2.png") },
        { url: require("./assets/img/cd/car2.png") },
        { url: require("./assets/img/cd/per2.png") },
        { url: require("./assets/img/cd/mony2.png") },
        { url: require("./assets/img/cd/ticheng1.png") },
        { url: require("./assets/img/cd/map.png") },
        { url: require("./assets/img/cd/shu1.png") },
        { url: require("./assets/img/cd/manage1.png") },
        { url: require("./assets/img/cd/set1.png") },
        { url: require("./assets/img/cd/bill2.png") },
        { url: require("./assets/img/cd/bill2.png") },
      ],
      pathUrl: [
        "/",
        "/Order",
        "/Business",
        "/Vehicle",
        "/User",
        "/Finance",
        "/Commission",
        "/GroupCompany",
        "/Maps",
        "/Statistics",
        "/set",
        "/Setting",
        "/sports",
        "/bill",
      ],
      emicti: false,
      emiccontent: false,
      emicreg: "",
      emiccallid: "",
      emiccalledid: "",
      un: "",
      pwd: "",
      //switchnumber: "057428861099",
      gid: 0,
      hastechcommission: false,
      src: require("./assets/cancelorder.mp3"),
      src1: require("./assets/neworder.mp3"),
      src2: require("./assets/message.mp3"),
      src3: require("./assets/orderback.mp3"),
      src4: require("./assets/98.mp3"),
      src5: require("./assets/99.mp3"),
      src6: require("./assets/blacklist.mp3"),
      weishouliId: "",

      jingyin: true,
      yijingyin: false,
      bohaopan: false,
      sanfang: false,
      dengdai: false,
      zhuanjie: false,
      hujiao: false,
      dialphone: "",
      guaduan: false,
      jieting: false,
      sanf: false,
      zhuanj: false,
      daildetail: {
        type: "",
        number: "",
      },
      otherEmic: [],
      rescueId: "",
      nowtimeshow: false,
      calllog: [],
      tokenid: "",

      sfnumber: "",

      yinsnatch: false,

      nums: [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"],
      newjieting: false,
      newguaduan: false,
      chongbo: true,
      newboda: true,
      yinenclosure: false,
      warningid: "",
      isames: "",
      tips: "",
      othertoken: "",
      otheruid: "",
      otherauth: "",
      otherNum: 15,
      other: false,
      other1: {
        num0: 0,
      },

      emicmode: "SIP",
      emicmodelist: [
        {
          name: "网页(无话机)",
          value: "WEBRTC",
        },
        {
          name: "话机",
          value: "SIP",
        },
        {
          name: "手机（勿用）",
          value: "PSTN",
        },
      ],
    };
  },
  methods: {
    change() {
      // this.$set(this.other1,0,11);

      // // this.$data.other1.num = 1;
      // console.log(this.other1);
      // this.$router.push('/change');
      if (this.othertoken != "") {
        Cookies.set("ntwl-token", this.othertoken, { domain: "nituowola.com" });
        Cookies.set("ntwl-uid", this.otheruid, { domain: "nituowola.com" });
        Cookies.set("ntwl-authkey", this.otherauth, {
          domain: "nituowola.com",
        });
        Cookies.set("ntwl-changecid", 1);
        this.tokenid = this.othertoken;
        let that = this;
        setTimeout(function () {
          that.initWebSocket();
          that.nav();
          that.messagecount();
          that.messagelist();
        }, 100);
        setTimeout(function () {
          that.$router.push("/change");
        }, 1000);
      } else {
      }
    },
    // 按键
    addNumber(num) {
      this.dialphone += num;

      vt.dtmf(num.toString());
    },
    // 清除
    clearNumber() {
      this.dialphone = "";
    },
    // 拨打  重播
    doCall(tel) {
      let ue = this.$store.state.emic;
      let mode = this.$store.state.emicmode;
      this.newboda = false;
      this.chongbo = false;
      this.newguaduan = true;
      //console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber: tel.toString(),
        loginType: mode,
      });
    },
    async enclosurebtn(id, relateid) {
      let res = await api.apimessageNew(id);

      this.warningid = relateid;
      //console.log(this.warningid);
      this.yinenclosure = true;
    },
    guanenclosure() {
      this.warningid = "";
      this.yinenclosure = false;
    },
    // 挂断
    vertoDecline() {
      this.newboda = true;
      this.chongbo = true;
      this.newguaduan = false;
      let ue = this.$store.state.emic;
      ue.call.hangup({
        success(e) {
          console.log("挂断成功", e);
        },
        fail(e) {
          console.log("挂断失败", e);
        },
      });
    },
    addFun(index) {
      if (index == 13) {
        this.ina = true;
      } else {
        this.ina = false;
      }
      // 先恢复上一次，再点谁谁改变
      this.$refs[`imgChange${this.flag2 + 1}`].src =
        this.oldImgs[this.flag2].url;
      if (this.flag > -1) {
        console.log("上一次的点击：", this.flag);
        this.$refs[`imgChange${this.flag}`].src =
          this.oldImgs[this.flag - 1].url;
      }
      console.log(
        "更改图片：",
        index,
        (this.$refs[`imgChange${index}`].src = this.imgs[index - 1].url)
      );
      this.flag = index;
    },
    snatchbtn() {
      this.yinsnatch = true;
    },
    guansnatch() {
      this.yinsnatch = false;
    },

    jing() {
      this.jingyin = false;
      this.yijingyin = true;
    },
    yijing() {
      this.yijingyin = false;
      this.jingyin = true;
    },

    bodahaoma() {
      this.bohaopan = true;
    },
    san() {
      this.getOther();
      this.sanfang = true;
    },
    deng() {
      this.dengdai = true;
    },
    zhuan() {
      this.getOther();
      this.zhuanjie = true;
    },
    hu() {
      this.hujiao = true;
      this.getcalllog();
    },
    guanhao() {
      this.bohaopan = false;
      this.sanfang = false;
      this.dengdai = false;
      this.zhuanjie = false;
      this.hujiao = false;
    },

    mouseenterFun() {
      this.isshow = true;
    },
    mouseleaveFun() {
      this.isshow = false;
    },
    ding() {
      this.ti = true;
    },
    dan() {
      this.ti = false;
    },
    lings() {
      this.other = true;
    },
    dangs() {
      this.other = false;
    },
    ling() {
      this.word = true;
    },
    dang() {
      this.word = false;
    },
    ntries() {
      this.can = true;
    },
    people() {
      this.can = false;
    },
    bodial() {
      this.yindial = true;
    },
    chudial() {
      this.yindial = false;
    },
    jilu() {
      this.yinjilui = true;
    },
    chujilu() {
      this.yinjilui = false;
    },
    changemessage(id) {
      this.noMore = false;
      this.tishi = "滚动查看更多";
      this.arr = id;
      if (this.arr) {
      }
      this.i = 1;
      this.messagelist();
    },
    apply(id) {
      this.cancel++;
      this.applyidd = id;
      if (this.cancel == 3) {
        this.cancel = 1;
      }
    },
    async bbll(id, relateid) {
      let res = await api.apimessageNew(id);

      this.newbtn(relateid);
    },
    newbtn(rescue_id) {
      console.log("新加内容", rescue_id);
      this.xinxi++;
      if (this.xinxi == 3) {
        this.xinxi = 1;
      }
      this.rescueId = rescue_id;
    },

    hunum(phonenum) {
      this.hujiao = false;
      this.bohaopan = true;
      this.dialphone = phonenum;
      console.log("点击值:", phonenum);
    },

    message() {
      this.mess = !this.mess;
      this.isto = false;
      this.emiccontent = false;
      this.messagelist();
    },
    head() {
      this.isto = !this.isto;
      this.mess = false;
      this.emiccontent = false;
    },
    set() {
      this.isset = true;
    },
    ovfound() {
      this.isset = false;
    },
    perr() {
      this.per++;
      if (this.per == 3) {
        this.per = 1;
      }
    },
    async gettoken() {
      let res = await api.login();

      Cookies.set("ntwl-token", res.data.token);
      Cookies.set("ntwl-uid", res.data.uid);
      Cookies.set("ntwl-authkey", res.data.authkey);
    },
    appr() {
      this.app++;
      if (this.app == 3) {
        this.app = 1;
      }
    },
    async shouli(id, rescue_id, behavior) {
      if (behavior == 101) {
        let res = await api.automationtake(id, rescue_id);
        if (res.code == 1) {
          this.xinxi++;
          if (this.xinxi == 3) {
            this.xinxi = 1;
          }
          this.rescueId = rescue_id;
          this.noMore = false; // 控制停止触底加载
          this.tishi = "滚动查看更多";
          this.i = 1;
          this.messagecount();
          this.messagelist();
        } else {
          this.$message.warning(res.message);
        }
      } else {
        let res = await api.messageaccept(id);
        if (res.code == 1) {
          this.accept++;
          if (this.accept == 3) {
            this.accept = 1;
          }
          this.weishouliId = rescue_id;
          this.noMore = false; // 控制停止触底加载
          this.tishi = "滚动查看更多";
          this.i = 1;
          this.messagecount();
          this.messagelist();
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    async recovery(id, rescue_id, behavior, isOp) {
      if (behavior == 102 && isOp == 0) {
        let res = await api.automationtake(id, rescue_id);
        if (res.code == 1) {
          this.accept++;
          if (this.accept == 3) {
            this.accept = 1;
          }
          this.weishouliId = rescue_id;
          this.noMore = false; // 控制停止触底加载
          this.tishi = "滚动查看更多";
          this.i = 1;
          this.messagecount();
          this.messagelist();
          this.mess = !this.mess;
        } else {
          this.$message.warning(res.message);
        }
      } else {
        this.accept++;
        if (this.accept == 3) {
          this.accept = 1;
        }
        this.weishouliId = rescue_id;
        this.noMore = false; // 控制停止触底加载
        this.tishi = "滚动查看更多";
        this.i = 1;
        this.messagecount();
        this.messagelist();
        this.mess = !this.mess;
      }
    },
    detail(id) {
      this.xinxi++;
      if (this.xinxi == 3) {
        this.xinxi = 1;
      }
      this.rescueId = id;
    },
    async apilook(id, rescueId) {
      let res = await api.apimessage(id);

      if (res.code == 1) {
        this.detail(rescueId);
      } else {
        this.$message.warning(res.message);
      }
    },

    feedr() {
      this.feed++;
      if (this.feed == 3) {
        this.feed = 1;
      }
      // this.feed = !this.feed;
    },
    install() {
      this.ina = !this.ina;
    },
    // document点击事件
    bodyHandler() {
      this.mess = false;
      this.isto = false;
      this.noMore = false; // 控制停止触底加载
      this.tishi = "滚动查看更多";
      this.i = 1;
      //this.isset = 0;
      this.app = 0;
      this.per = 0;
      this.feed = 0;
      this.cancel = 0;
      this.emiccontent = false;
    },
    logout() {
      localStorage.removeItem("token");
      Cookies.set("uuid", "");
      Cookies.set("queId", "");
      Cookies.set("ntwl-token", "", { domain: "nituowola.com" });
      Cookies.set("ntwl-uid", "", { domain: "nituowola.com" });
      Cookies.set("ntwl-authkey", "", { domain: "nituowola.com" });
      window.location.href = "https://www.nituowola.com";
    },
    // 消息列表请求方法
    async getMessageList(type, page, perpage) {
      let messageList = await api.messageList(type, page, perpage);
      this.messageList = [...this.messageList, ...messageList.data];
      if (messageList.data.length == 0) {
        this.noMore = true;
        this.tishi = "没有更多了";
      }
    },
    load() {
      console.log("触底加载启动！");
      this.i++;
      let type = this.arr - 1;
      let page = this.i;
      let perpage = 5;
      this.getMessageList(type, page, perpage);
    },
    async nav() {
      let res = await api.workNav();
      this.navList = res.data;

      //console.log(this.navList);

      let res1 = await api.workLeft();
      this.leftList = res1.data;
      this.hasemic = this.leftList.hasemic;
      if (
        (this.leftList.queId != "" || this.leftList.queId2 != "") &&
        this.hasemic == true
      ) {
        if (this.leftList.queId2 != "") {
          Cookies.set("queId", this.leftList.queId2);
        } else {
          Cookies.set("queId", this.leftList.queId);
        }
        //Cookies.set("queId", this.leftList.queId);

        Cookies.set(
          "uuid",
          "73836387-0000-0000-0000-0000-" +
            "00000" +
            (Math.random() * 100000 + 10000)
        );
      }
      this.hastechcommission = this.leftList.istechcommission;
      this.$store.commit("getmain", res1.data.ismain);
      this.$store.commit("getshow", res1.data.istechcommission);
      this.$store.commit("getfin", res1.data.hasFinance);
      this.ismain = res1.data.ismain;
      this.othertoken = res1.data.othertoken;
      this.otheruid = res1.data.otheruid;
      this.otherauth = res1.data.otherauth;
      this.othergid = res1.data.othergid;

      if (this.leftList.changepass == true) {
        this.isames = 1;
        this.tips = 1;
      }

      if (this.leftList.hasWork == false) {
        if (this.leftList.hasOrder == true) {
          if (this.leftList.ismain == true) {
            this.addFun(2);
            this.$router.push("/Order");
          } else {
            this.addFun(3);
            this.$router.push("/OrderG");
          }
        } else if (this.leftList.hasPartner == true) {
          this.addFun(4);
          this.$router.push("/Business");
        } else if (this.leftList.hasUser == true) {
          this.addFun(7);
          this.$router.push("/User");
        } else if (this.leftList.hasTech == true) {
          this.addFun(6);
          this.$router.push("/Vehicle");
        } else if (
          this.leftList.hasFinance == true ||
          this.leftList.saleFinance == true
        ) {
          if (this.leftList.ismain == true) {
            if (this.leftList.isNew == true) {
              this.addFun(8);
              this.$router.push("/FinanceNew");
            } else {
              this.addFun(8);
              this.$router.push("/Finance");
            }
          } else {
            this.addFun(8);
            this.$router.push("/FinanceG");
          }
        } else if (this.leftList.istechcommission == true) {
          this.addFun(9);
          this.$router.push("/Commission");
        } else if (this.leftList.hasMaps == true) {
          this.addFun(10);
          this.$router.push("/Maps");
        }
      } else {
      }
    },
    async messagecount() {
      // 铃铛
      let messageNum = await api.messageNum();
      this.messageNum = messageNum.data.number;
      this.shareNumber = messageNum.data.shareNumber;
      this.otherNum = messageNum.data.otherNum;
    },
    async messagelist() {
      // 分类别的，消息列表请求
      if (this.mess == true) {
        let messageList = await api.messageList(this.arr - 1, 1, 5);
        this.messageList = messageList.data;
      }

      console.log("----", this.messageList);
    },
    initWebSocket() {
      createWebSocket(this.global_callback);
    },

    global_callback(msg) {
      if (this.ismain == true) {
        console.log("appvue的回调函数收到服务器信息：" + JSON.stringify(msg));

        var cmds = msg.split(":");
        var kind = cmds.shift();
        var action;

        //console.log(kind);

        if (kind === "message") {
          action = cmds.shift();
          this.messagecount();
          this.messagelist();
          //console.log(action);
          if (action === "new" && this.navList.hasvoice == 1) {
            this.$refs.audio1.play();
          } else if (action == "orderhuishou") {
            if (this.navList.hasvoice == 1) {
              this.$refs.audio3.play();
            }
          }
        } else if (kind === "cancelmessage") {
          this.messagecount();
          this.messagelist();
          if (this.navList.hasvoice == 1) {
            this.$refs.audio.play();
          }
        } else if (kind == "apimessage") {
          this.messagecount();
          this.messagelist();
          if (this.navList.hasvoice == 1) {
            this.$refs.audio2.play();
          }
        } else if (kind == "ef98message") {
          this.messagecount();
          this.messagelist();
          if (this.navList.hasvoice == 1) {
            this.$refs.audio4.play();
          }
        } else if (kind == "ef99message") {
          this.messagecount();
          this.messagelist();
          if (this.navList.hasvoice == 1) {
            this.$refs.audio5.play();
          }
        } else if (kind == "blacklist") {
          this.messagecount();
          this.messagelist();
          if (this.navList.hasvoice == 1) {
            this.$refs.audio6.play();
          }
        } else if (kind === "connection") {
          action = cmds.shift();
          if (action === "closed") {
            let that = this;
            setTimeout(function () {
              //console.log(222);
              that.initWebSocket();
            }, 2000);
          }
        } else if (kind === "online") {
          this.messagecount();
          sendSock("0x0A");
        } else if (kind == "othermessage") {
          this.messagecount();
        } else if (kind == "automationmessage") {
          this.messagecount();
          this.messagelist();
        }
      } else {
      }
    },
    emiconfun() {
      this.emicti = true;
    },
    emicofffun() {
      this.emicti = false;
    },
    emicc() {
      this.emiccontent = !this.emiccontent;
      this.mess = false;
      this.isto = false;
      //this.calltimebegin();
    },
    closeemic() {
      this.emiccontent = false;
    },
    websocket_connect() {
      let that = this;
      var ue = new ueSoftPhone({
        accountName: this.un,
        password: this.pwd,
        loginType: this.emicmode,
        success: function (e) {
          console.log("成功", e);
        },
        error: function (e) {
          console.log("失败", e);
          that.$message.warning("云呼叫登录失败："+ e.message);
        },
      });

      this.$store.state.emicmode = this.emicmode;

      ue.listenCallEvent({
        success(res) {
          console.log(res, "监听通话成功");
        },
        message: (res) => {
          console.log(res, "接收通话事件成功");
          if (res.subtype == "login") {
            this.$message.success("云呼叫登录成功");
            this.$store.state.emic = ue;
            this.emiccontent = false;
          } else if (res.subtype == "status") {
            let state = res.data.state;
            let x = res.data;
            if (state == 0) {
              this.bodahao = true;
              this.jinbo = false;
              this.jieting = false;
              this.guaduan = false;
              this.sanf = false;
              this.zhuanj = false;
              this.dengd = false;
              this.$store.state.emiccallid = "";
              this.$store.state.emiccalledid = "";
              this.daildetail.type = "";
              this.daildetail.number = "";
              this.dialphone = "";
              this.newboda = true;
              this.chongbo = true;
              this.newguaduan = false;

              if (x.eventType == "hangup") {
                this.calltimeend();
              }
            } else if (state == 2) {
              this.bodahao = false;
              this.jinbo = true;
              if (x.callType == "callout") {
                this.daildetail.type = "正在呼出";
                this.gettelname(x.customerNumber);
                this.$store.state.emiccallid = x.call_accept;
                this.guaduan = true;

                this.savelogX(x);
              }
            } else if (state == 3) {
              if (x.callType == "callin") {
                this.daildetail.type = "正在呼入";
                this.gettelname(x.customerNumber);
                this.jieting = true;
                this.zhuanj = true;
                this.guaduan = true;
                this.sanf = true;
                this.$store.state.emiccalledid = x.call_accept;

                this.savelogX(x);
              }
            } else if (state == 4) {
              this.calltimebegin();
              this.zhuanj = true;
              this.guaduan = true;
              this.sanf = true;
            }
          }
        },
        event: (res) => {
          console.log(res, "socket互踢");
          this.$message.success("云呼叫被顶");
          window.location.reload();
        },
      });
    },
    olalogout() {
      this.$message.success("退出登录");
      window.location.reload();
    },
    ola_callback2(msg) {
      var x = JSON.parse(msg);
      console.log("xxxadad", x);
    },
    ola_callback(msg) {
      var x = JSON.parse(msg);
      //console.log(x);
      if (x.event_type == "command/reply" && x.code == 200 && x.cmd == "auth") {
        ola.olasubscribe("ola.agent." + this.un);
        ola.olasubscribe("ola.caller." + this.pwd);
        ola.olaget_agent_state();
        ola.olaloginout();
        ola.olalogin();
        vt.vertoLogin(this.un, this.pwd);
      } else if (x.event_type == "command/reply" && x.code == 403) {
        this.$message.warning(x.text);
      } else if (x.event_type == "agent_state") {
        //
        if (x.state == "login") {
          this.$message.success("云呼叫登录成功");
          ola.go_ready();
          this.$store.state.emic = x.agent_extn;
          this.emiccontent = false;
        } else if (x.state == "logout") {
          this.$message.success("云呼叫成功退出");
          // setTimeout(function () {
          //   window.location.reload();
          // }, 1000);
        } else if (x.state == "ready") {
          this.bodahao = true;
          this.jinbo = false;
          this.jieting = false;
          this.guaduan = false;
          this.sanf = false;
          this.zhuanj = false;
          this.dengd = false;
          this.$store.state.emiccallid = "";
          this.$store.state.emiccalledid = "";
          this.daildetail.type = "";
          this.daildetail.number = "";
          this.dialphone = "";
          this.newboda = true;
          this.chongbo = true;
          this.newguaduan = false;
        } else if (x.state == "unready") {
        } else if (x.state == "acw") {
          this.jieting = false;
          this.guaduan = false;
          this.sanf = false;
          this.zhuanj = false;
          this.dengd = false;
          this.$store.state.emiccallid = "";
          this.$store.state.emiccalledid = "";
          this.daildetail.type = "";
          this.daildetail.number = "";
          this.calltimeend();
          ola.go_ready();
        } else if (x.state == "busy") {
          this.bodahao = false;
          this.jinbo = true;
          let that = this;
          if (x.call_direction == "outbound") {
            if (x.old_state == "busy") {
              this.guaduan = true;
              this.sanf = true;
              this.jieting = false;
              this.zhuanj = true;
              this.$store.state.emiccalledid = x.call_accept;
              if (x.app_data.other_answered == "true") {
                this.calltimebegin();
              }

              this.savelog(x);
            } else {
              this.daildetail.type = "正在呼出";
              this.gettelname(x.app_data.dest_number);
              //this.daildetail.number = ;
              this.$store.state.emiccallid = x.call_accept;
              setTimeout(function () {
                that.answer();
              }, 1000);
            }
          } else if (x.call_direction == "inbound") {
            this.daildetail.type = "正在呼入";
            this.gettelname(x.app_data.cid_number);

            //this.daildetail.number = x.app_data.cid_number;
            if (x.old_state == "busy") {
              this.jieting = false;
              this.zhuanj = true;
              this.guaduan = true;
              this.sanf = true;
              this.$store.state.emiccalledid = x.call_accept;
              this.calltimebegin();
              this.savelog(x);
            } else {
              setTimeout(function () {
                that.answer();
              }, 1000);
              this.jieting = true;
            }
          } else if (x.call_direction == "undefined") {
            if (this.$store.state.emiccalledid == "") {
              setTimeout(function () {
                that.answer();
              }, 1000);
              this.jieting = true;
            }
            this.sanf = false;
            this.guaduan = true;
            this.$store.state.emiccalledid = x.call_accept;
          }
        } else {
          console.log("xxx", x);
        }
      }
    },
    //拨打  拨号机拨打
    call(tel, str) {
      console.log("拨打电话", tel, str);
      if (this.bohaopan == true) {
        this.bohaopan = false;
        this.dialphone = "";
      }
      let ue = this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber: tel.toString(),
        loginType: mode,
        extras: str,
      });
      this.bodahao = false;
      this.jinbo = true;
    },
    //接听
    answer() {
      let ue = this.$store.state.emic;
      ue.webrtc.accept();
      this.jieting = false;
    },
    //保持
    hold() {
      let ue = this.$store.state.emic;
      ue.call.holdOrUnHold({
        type: 1,
      });
      this.jingyin = false;
      this.yijingyin = true;
    },
    //取消保持
    unhold() {
      let ue = this.$store.state.emic;
      ue.call.holdOrUnHold({
        type: 2,
      });
      this.jingyin = true;
      this.yijingyin = false;
    },
    //挂断
    hangup() {
      let ue = this.$store.state.emic;
      ue.call.hangup({
        success(e) {
          console.log("挂断成功", e);
        },
        fail(e) {
          console.log("挂断失败", e);
        },
      });
      this.bodahao = true;
      this.jinbo = false;
    },
    //转接
    olatransfer(transNum) {
      let ue = this.$store.state.emic;
      ue.call.transfer({
        success(e) {
          console.log("转接成功", e);
        },
        fail(e) {
          console.log("转接失败", e);
        },
        number: transNum.toString(),
        type: "agent",
      });
    },
    //三方通话
    olathree(transNum) {
      ola.three_way(transNum);
    },
    //邀请非分机人三方
    olathree2() {
      ola.three_way(this.sfnumber);
    },
    //获取当前空闲的分机
    async getOther() {
      let ue = this.$store.state.emic;
      let that = this;
      ue.agent.findIdleAgentsForQueue({
        success(e) {
          console.log("获取服务组空闲", e);
          let data = e.dataList;

          let newdata = [];
          data.forEach(function (x) {
            console.log(x);
            if (x.members.length > 0) {
              x.members.forEach(function (y) {
                newdata.push({ extn: y.agentNumber, name: y.agentName });
              });
            }
          });

          console.log(newdata);
          that.otherEmic = newdata;
        },
        fail(e) {
          console.log("获取服务组失败", e);
        },
      });
      // this.otherEmic = [];
      // let res = await api.getOtherEmic(this.un);
      // this.otherEmic = res.data;
    },
    async savelog(x) {
      if (this.$store.state.emic != "") {
        let res = await api.savelog(x);
      }
    },
    async savelogX(x) {
      if (this.$store.state.emic != "") {
        let res = await api.savelogX(x);
      }
    },
    async gettelname(num) {
      let res = await api.gettelname(num);
      this.daildetail.number = res;
    },
    calltimebegin() {
      clearInterval(nowtimer);
      this.nowtimeshow = true;
      let show = "";
      nowtimer = setInterval(function () {
        nowtime++;
        if (nowtime < 60) {
          if (nowtime < 10) {
            nowtime = "0" + nowtime;
          }
          show = "00:" + nowtime;
        } else {
          let hour = parseInt(nowtime / 60);
          let min = nowtime % 60;
          if (hour < 10) {
            hour = "0" + hour;
          }
          if (min < 10) {
            min = "0" + min;
          }
          show = hour + ":" + min;
        }
        console.log(show);
        tonghua.innerHTML = "通话中 " + show;
      }, 1000);
    },
    calltimeend() {
      clearInterval(nowtimer);
      nowtimer = "";
      this.nowtimeshow = false;
      nowtime = 0;
    },
    async getcalllog() {
      this.calllog = [];
      let res = await api.getcalllog();
      this.calllog = res.data;
    },
  },

  async created() {
    let token = Cookies.get("ntwl-token");
    this.tokenid = token;
    let uid = Cookies.get("ntwl-uid");
    let authkey = Cookies.get("ntwl-authkey");

    if (
      token == undefined ||
      token == "" ||
      uid == "" ||
      authkey == "" ||
      uid == undefined ||
      authkey == undefined
    ) {
      this.$message.warning("请先登录");
      window.location.href = "https://www.nituowola.com";
    }

    this.initWebSocket();
    this.nav();
    this.messagecount();
    this.messagelist();
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getCallid() {
      return this.$store.state.emiccallid;
    },
    getCalledid() {
      return this.$store.state.emiccalledid;
    },
    getismain() {
      return this.$store.state.ismain;
    },
    stype() {
      return this.$store.state.stype;
    },
  },
  watch: {
    getEmic(newval) {
      this.emicreg = newval;
    },
    getCallid(newval) {
      this.emiccallid = newval;
    },
    getCalledid(newval) {
      this.emiccalledid = newval;
    },
    getismain(newval) {
      this.ismain = newval;
    },
    nowtime(newval) {
      this.nowtime = newval;
    },
    stype(newval) {
      this.addFun(8);
      console.log("vuex*******", newval);
    },
  },

  async mounted() {
    let res = await api.workNav();

    let usernum = res.data.mobile;
    let nicknum = usernum.slice(-4);
    Watermark.set(res.data.sname + " " + res.data.username + nicknum);

    document.addEventListener("click", this.bodyHandler);

    let index = this.pathUrl.findIndex((item, index, arr) => {
      return window.history.state.current == item;
    });
    if (this.$refs[`imgChange${index + 1}`]) {
      this.$refs[`imgChange${index + 1}`].src = this.imgs[index].url;
    } else {
      index = 7;
      this.$refs[`imgChange${index + 1}`].src = this.imgs[index].url;
    }
    this.flag2 = index;
  },
  unmounted() {
    document.removeEventListener("click", this.bodyHandler);
  },
};
</script>

<style lang="less" scoped>
.orderHint,
.bell,
.nom,
.emicshow {
  width: 70px;
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  background-color: #6d6262;
  color: #fff;
  position: absolute;
  top: 65px;
  right: -25px;
}

.infinite-list {
  width: 100%;
  height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list li {
  width: 100%;
  position: relative;
  background-color: #fff;
}
.infinite-list li:nth-child(odd) {
  background-color: #fafafa;
}
.infinite-list li:hover {
  background-color: #f9fafe;
}

.infinite-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 50px;
  margin: 10px;
  // color: var(--el-color-primary);
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}
.infinite-list-item {
  cursor: pointer;
}

.cancelUl {
  padding: 0;
  width: 100%;
  // background-color: skyblue;
}

.cancelUl li {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background-color: #fff;
  margin: 10px;
}
.cancelUl li:nth-child(odd) {
  background-color: #fafafa;
}
.cancelUl li:hover {
  background-color: #f9fafe;
}

.cancenTit {
  font-size: 14px;
  color: #2a3346;
}
.cancelId {
  color: #727e96;
  font-size: 14px;
}
.cancelTai,
.cancelTai2,
.cancelTaiX {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 20px;
  padding: 4px 12px;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  right: 10px;
}
.cancelTai {
  background-color: #ff9100;
}
.cancelTaiX {
  background-color: #009688;
}
.cancelTai2 {
  background-color: #9ea3b9;
}

.sp-title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  position: absolute;
  left: 20px;
  top: -1px;
}
.sp-title1 {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}
.case {
  background-color: #ff9100;
  font-size: 12px;
  padding: 4px 12px;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  right: 10px;
}
.p-title {
  color: #727e96;
  font-size: 14px;
}

.message {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: red;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 18px;
  text-align: center;
}

.message1 {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: red;
  position: absolute;
  left: 20px;
  top: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 18px;
  text-align: center;
}
.qiangmessage {
  width: 18px;
  height: 18px;
  background-color: #2c68ff;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  position: absolute;
  right: 2px;
  border: 1px solid #e0e0e0;
}
.item {
  position: relative;
  top: -100px;
  left: 193px;
  width: 190px;
  height: 120px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  opacity: 1;
  border-radius: 2px;
}
.item1 {
  width: 190px;
  height: 60px;
  box-sizing: border-box;
  // background-color: skyblue;
}
.tion {
  background-color: #f7f9fb;
}
.item1-span {
  width: 84px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 11px;
  color: #2a3346;
  opacity: 1;
}

.boxtotal {
  width: 1920px;
  height: 920px;
  margin: 0 auto;
  box-sizing: border-box;
  // background-color: gray;
  position: relative;
}
.nav-box {
  width: 1920px;
  height: 80px;
  margin: 0 auto;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 10px #eceff1;
  opacity: 1;
  z-index: 5;
  display: flex;
  justify-content: space-between;
}
.main-box {
  width: 1920px;
  height: 840px;
  margin: 0 auto;
  // background-color: orangered;
}
.left-box {
  float: left;
  width: 190px;
  height: 840px;
  margin: 0 auto;
  // background-color: gold;
}
// 头部导航
.logo {
  float: left;
  width: 170px;
  height: 35px;
  margin-left: 50px;
  margin-top: 24px;
}
.i1 {
  float: left;
  width: 0px;
  height: 30px;
  box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
  margin-top: 26px;
  margin-left: 17px;
}
span {
  float: left;
  // width: 198px;
  height: 24px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 32px;
  color: #2a3346;
  opacity: 1;
  margin-left: 19px;
  margin-top: 24px;
}
.inform {
  float: left;
  width: 32px;
  height: 32px;
  margin-top: 30px;
  cursor: pointer;
}
.navright {
  float: right;
  margin-right: 106px;
}
.local,
.qianglocal {
  float: left;
  position: relative;
}

// 点击消息显示
.advices {
  //width: 500px;
  height: 380px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  opacity: 1;
  position: absolute;
  top: 89px;
  right: -11px;
  background-color: #fff;
  z-index: 11;
}
.advices::before {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  position: absolute;
  right: 19px;
  top: -18px;
  border: 9px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.advices-box {
  //width: 500px;
  display: flex;
  height: 50px;
  background: #ffffff;
  opacity: 1;
  border-radius: 8px 8px 0px 0px;
}
.advices-bootm {
  // position: absolute;
  // right: 15px;
  // top: 15px;
  // width: 400px;
  // height: 50px;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
a {
  text-decoration: none;
}

.all,
.ding,
.system {
  float: left;
  cursor: pointer;
}
.all {
  width: 64px;
  height: 22px;
  font-size: 16px;
  margin-left: 35px;
  font-family: PingFang SC;
  opacity: 1;
  text-align: center;
  line-height: 50px;
}
.ding {
  // width: 64px;
  width: 70px;
  height: 22px;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  margin: 0 14px;
  //margin-left: 28px;
  font-family: PingFang SC;
  color: #727e96;
  opacity: 1;
}

.system {
  margin-left: 30px;
  // width: 64px;
  // height: 22px;
  font-size: 16px;
  line-height: 50px;
  font-family: PingFang SC;
  // color: #727e96;
  opacity: 1;
  font-weight: bold;
}
.all-main {
  width: 400px;
  height: 80px;
  // margin-left: -35px;
  margin-top: 0px;
  opacity: 1;
  background-color: #fff;
  border-radius: 0px;
}
.main-p1 {
  width: 320px;
  height: 22px;
  font-size: 16px;
  margin-left: 10px;
  padding-top: 17px;
  margin-bottom: 0;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2a3346;
  opacity: 1;
}
.main-p2 {
  width: 219px;
  height: 20px;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.action {
  padding-bottom: 15px;
  font-size: 16px;
  color: #2c68ff;
  font-weight: bold;
  border-bottom: 1px solid #2c68ff;
}

.order {
  float: left;
  width: 32px;
  height: 32px;
  margin-top: 30px;
  margin-left: 23px;
  cursor: pointer;
}
.weiXin {
  float: left;
  width: 32px;
  height: 32px;
  margin-top: 30px;
  margin-left: 23px;
  cursor: pointer;
}
// 鼠标悬浮显示
.erMa {
  width: 140px;
  height: 309px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  top: 89px;
  right: -11px;

  z-index: 11;
}
.erMa::before {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  position: absolute;
  left: 105px;
  top: -18px;
  border: 9px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.ma {
  width: 90px;
  height: 90px;
  margin: 19px 25px 10px 25px;
}
.title {
  float: left;
  width: 140px;
  text-align: center;
  height: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #383a42;
  // margin-left: 27px;
  opacity: 1;
}
.line {
  float: left;
  width: 140px;
  height: 0px;
  border: 1px solid #eceff1;
  opacity: 1;
}

.head-box {
  float: left;
  // width: 60px;
  height: 60px;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 0px;
  margin-left: 13px;
  padding: 10px;
  margin-top: 20px;
  // background-color: red;
}
.head-img {
  width: 32px;
  height: 32px;
  margin: 0;
  // background: #f0830d;
  // border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}
.head-img1 {
  width: 32px;
  height: 32px;
  margin: 0;
  margin-left: 23px;
  margin-top: 30px;
  // background: #f0830d;
  // border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}
// 个人中心
.main1 {
  width: 270px;
  height: 48px;
  box-sizing: border-box;
  float: left;
}
.head-display {
  width: 270px;
  // height: 329px;
  opacity: 1;
  background-color: #ffffff;
  position: absolute;
  right: -11px;
  top: 89px;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
}
.head-display::before {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  position: absolute;
  right: 20px;
  top: -18px;
  border: 9px solid #ffffff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.display-nav {
  width: 270px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 8px 8px 0px 0px;
}
.display-main {
  display: flex;
  flex-direction: column;
  width: 270px;
  // height: 190px;
  border-bottom: 2px solid #f9fafb;
  padding-bottom: 14px;
  background-color: #fff;
}
.display-img {
  width: 16px;
  height: 16px;
  margin-left: 30px;
  margin-top: 15px;
  float: left;
}
.display-bottom {
  display: flex;
  width: 270px;
  height: 60px;
  background-color: #fff;
  box-sizing: border-box;
  // background: red;
  opacity: 1;
  border-radius: 0px;
}
.tui {
  margin-top: 22px;
}
.display-title {
  // width: 56px;
  // height: 19px;
  font-size: 14px;
  float: left;
  // margin-top: 28px;
  margin-left: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
  color: #2a3346;
  opacity: 1;
  cursor: pointer;
}
.tuichu {
  line-height: 60px;
}
.head-p {
  float: left;
  // width: 70px;
  height: 19px;
  font-size: 14px;
  margin-left: 18px;
  margin-top: 35px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  // line-height: 0px;
  color: #2a3346;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

// 左边列表
.left-box {
  float: left;
  width: 190px;
  height: 840px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.16);
  opacity: 1;
  margin-right: 2px;
}
ul {
  list-style: none;
  width: 190px;
  padding-top: 50px;
}
li {
  width: 190px;
  height: 60px;
}

.li-img1,
.li-img2,
.li-img3,
.li-img4,
.li-img5,
.li-img6,
.li-img7,
.li-img8,
.li-img9,
.li-img10,
.li-img11,
.li-img12,
.li-img13,
.li-img15,
.li-img14 {
  float: left;
  width: 24px;
  height: 24px;
  margin-left: 30px;
  margin-top: 18px;
}

.s1 {
  float: left;
  width: 60px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  margin-left: 17px;
  margin-top: 30px;
}
// .set {
//   display: inline-block;
//   margin-top: 300px;
// }
.leftSix {
  margin-bottom: 80px;
}
.leftSix2 {
  margin-bottom: 250px;
}
.router-link-active {
  position: relative;
}

.router-link-active li {
  position: relative;
}

.router-link-active .s1 {
  color: #2c68ff;
}
.xuanweb:hover {
  background: #dfe8ff;
  border-right: 2px solid #2c68ff;
}

.router-link-active::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 60px;
  background: #2c68ff;
  position: absolute;
  left: 188px;
  top: 0;
}

.router-link-active::before {
  content: "";
  display: inline-block;
  width: 190px;
  height: 60px;
  background: #dfe8ff;
  position: absolute;
  left: 0px;
  top: 0;
}

.emiccontent {
  top: 80px;
  width: 344px;
  height: 285px;
  background: url(./assets/img/index/bj.png);
  position: absolute;
  right: -75px;
  z-index: 100;
  background-size: 100% 100%;
}
.emictitle {
  padding-top: 10px;
  padding-left: 10px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 20px;
}
.emicbody {
  width: 100%;
  height: 176px;
  padding-top: 30px;
  padding-left: 20px;
}
.un,
.pwd,
.sub {
  height: 32px;
  line-height: 32px;
  // margin-left: 20px;
  display: flex;
}
.sub {
  margin-left: 80px;
}
.pwd {
  margin-top: 10px;
}
.sub {
  margin-top: 20px;
  text-align: center;
}
.x {
  float: left;
  // width: 70px;
  margin-top: 6px;
  height: 24px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  text-align: right;
}
.y {
  float: left;
  // width: 200px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d9d9d9;
}
.y1 {
  height: 24px;
  opacity: 1;
  border: none;
  padding-left: 12px;
  border-color: #000000 !important;
  outline: 0;
}
.emicsub,
.emiccancel,
.emicout {
  width: 60px;
  height: 32px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  cursor: pointer;
}
.emicsub {
  background: #27cc8b;
  color: white;
  border: none;
}

.emicout {
  background: #ff505a;
  color: white;
  border: none;
}
.emiccancel {
  margin-left: 10px;
  background: white;
  border-radius: 4px 4px 4px 4px;
  color: rgba(0, 0, 0, 0.85);
  border-color: 1px solid #d9d9d9;
}
.jieting {
  width: 92px;
  height: 32px;
  background: url(./assets/img/index/answer.png);
  cursor: pointer;
  border: none;
  position: absolute;
  top: 23px;
  right: 1000px;
}
.guaduan {
  width: 92px;
  height: 32px;
  background: url(./assets/img/index/guaduan.png);
  cursor: pointer;
  border: none;
  position: absolute;
  top: 23px;
  right: 900px;
}

.emic {
  float: left;
  width: 32px;
  height: 32px;
  margin-top: 30px;
  margin-left: 23px;
  cursor: pointer;
}

// .active {
//   width: 190px;
//   height: 60px;
//   background: #f9fafe;
//   opacity: 1;
//   border-radius: 0px;
//   border-right: 3px solid #2c68ff;
// }
// .active .s1 {
//   color: #2c68ff;
// }

// 通话
.conversation {
  // width: 500px;
  height: 32px;
  background-color: #eeeeee;
  padding: 5px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  // position: absolute;
  // top: 20px;
  right: 600px;
  display: flex;
  align-items: center;
}
.tonghuazhong {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.tongImg {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  cursor: pointer;
  // margin: 0 5px;
}
.tongTit {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  margin: 0 5px;
}
// .jianju {
//   margin-left: 12px;
// }
.dengNum {
  display: inline-block;
  color: #ff0000;
  margin-left: 4px;
}
.dianhua {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
.jingyin {
  color: #bfbfbf;
}

// 拨号
.bohaoBox,
.sanfangBox {
  width: 1920px;
  height: 940px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.bohao,
.sanfang {
  width: 450px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
}
.bohao {
  width: auto;
  // height: 187px;
}
.sanfang {
  height: 294px;
}
.bohaoNav {
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  padding: 12px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
}
.bohaoImg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.bohaoTit {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
.bohaoMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.haoma {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 12px;
  height: 46px;
  text-align: center;
  font-size: 24px;
  outline: none;
  border: 0;
}
.boda {
  width: 302px;
  height: 32px;
  background-color: #61d29e;
  color: #fff;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}
.boda:hover {
  background-color: #3cc184;
}
.sanfangMain {
  width: 100%;
  height: 240px;
  padding: 10px 20px;
  z-index: 10;
  box-sizing: border-box;
  overflow-y: scroll;
  // margin-top: 24px;
}
.sanfangTable {
  width: 100%;
}
.sanfangthead {
  height: 32px;
}
.sanfangthead th {
  padding-left: 12px;
  text-align: left;
}
.sanfangTr {
  height: 40px;
  border: 1px solid #e5e5e5;
}
.sanfangTr td {
  padding-left: 12px;
}
.bodahunum {
  cursor: pointer;
}
.yaoqing {
  color: #2c68ff;
  cursor: pointer;
}

.huabox {
  width: 800px;
  height: 50px;
  // background-color: pink;
  float: left;
  margin-top: 15px;
  //margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dial {
  width: 70px;
  position: absolute;
  top: 25px;
  left: -25px;
  padding: 3px 5px;
  color: #fff;
  text-align: center;
  background-color: #6d6262;
}
.bohaoma {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  cursor: pointer;
  margin: 0 5px;
}
.hujiao {
  margin: 0 5px;
}
.sanfangnum {
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #666;
  outline: none;
}

.qiangimg {
  width: 120px;
  height: 32px;
  float: left;
  margin-top: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.qiangimg2 {
  width: 60px;
  height: 32px;
  float: left;
  margin-top: 30px;
  margin-right: 15px;
  cursor: pointer;
  border-radius: 5px;
  background: #2c69ff;
  color: white;
  border: none;
  box-shadow: none;
}

.callDial {
  width: 240px;
  height: auto;
  // position: fixed;
  // top: 3%;
  // right: 20px;
  background-color: #fff;
  text-align: center;
  padding: 15px;
  // box-shadow: -2px 0px 8px #d1d1d1;
  cursor: pointer;
  transition: all 0.2s linear;
  border-radius: 5px 0 0 5px;
  z-index: 999;
}

#dest_phone {
  width: 96%;
  height: 30px;
  line-height: 30px;
  border: 1px solid #d1d1d1;
  outline: none;
  border-radius: 3px;
  padding-left: 7px;
  margin-bottom: 10px;
}
#incomingCall {
  font-size: 13px;
  text-align: center;
  display: none;
  padding: 5px;
  clear: both;
  background-color: #f6ffed;
  color: #52c41a;
  /* border: 1px solid #B7EB8F;*/
  margin-bottom: 10px;
}
.phonebtn {
  padding: 7px 14px;
  cursor: pointer;
  border-radius: 3px;
  outline: none !important;
  font-size: 12px !important;
  margin-right: 10px;
  // display: none;
}
.show {
  display: inline-block !important;
}
/*拨打*/
#dialBtn {
  background-color: #1890ff;
  color: #fff !important;
  border: 1px solid #1890ff;
}
#dialBtn:hover {
  background-color: #1681e5;
}
/*清除*/
#clearBtn {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  margin-right: 0 !important;
}
#clearBtn:hover {
  background-color: #eee;
}
/*重播*/
#againBtn {
  background-color: rgb(0, 188, 212);
  border: 1px solid rgb(0, 188, 212);
  color: #fff;
}
#againBtn:hover {
  background-color: #05aabf;
}
/*接听*/
#answerBtn {
  background-color: #52c41a;
  border: 1px solid #52c41a;
  color: #fff;
}
#answerBtn:hover {
  background-color: #4ab017;
}
/*挂断*/
#hangupBtn {
  background-color: #f44336;
  border: 1px solid #f44336;
  color: #fff;
}
#hangupBtn:hover {
  background-color: #ea1c0d;
}

#dialpad button {
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 50px !important;
  height: 34px;
  padding: 0 !important;
  box-shadow: none !important;
  // background-color: #eee;
  margin: 0 15px 12px 0;
  cursor: pointer;
}
#dialpad button:hover {
  background-color: #ddd;
}

.el-select-dropdown__item {
  width: 183px;
  line-height: 30px;
  height: 30px;
}
</style>
