import http from "../util/http.js";
import http1 from "../util/http1.js";
import http2 from "../util/http2.js";
import http3 from "../util/http3.js";
// 登录
export const login = () => {
  return http
    .post("/site/do-login", {
      username: '18657437500',
      password: "lx870212505050",
      type: "1",
    })
    .then((res) => res.data);
};

// 网页顶部导航
// /sys/get-top
export const workNav = () => {
  return http
    .get("/sys/get-top ", {
      params: {},
    })
    .then((res) => res.data);
};

// 网页左侧
// /sys/get-column
export const workLeft = () => {
  return http
    .get("/sys/get-column", {
      params: {},
    })
    .then((res) => res.data);
};

//


// 工作台订单列表
// /order/plat-list GE
export const workOrder = (data) => {
  let {
    type,
    page,
    perpage,
    keytype,
    keyword,
    rescueStyle,
    createStart,
    createEnd,
    yuyueStart,
    yuyueEnd,
    city,
    county,
    saleman,
    tech,
    platshow,
    isOpen,
    isShare
  } = data;
  return http1
    .get("/order/plat-list-n-x", {
      params: {
        type,
        page,
        perpage,
        keytype,
        keyword,
        rescueStyle,
        createStart,
        createEnd,
        yuyueStart,
        yuyueEnd,
        city,
        county,
        saleman,
        tech,
        platshow,
        isOpen,
        isShare
      },
    })
    .then((res) => res.data);
};

export const workOrderSx = (data) => {
  let {
    type,
    page,
    perpage,
    keytype,
    keyword,
    rescueStyle,
    createStart,
    createEnd,
    yuyueStart,
    yuyueEnd,
    city,
    saleman,
    tech,
    platshow,
    isOpen,
    isShare,
  } = data;
  return http1
    .get("/order/plat-list-n-x-sx", {
      params: {
        type,
        page,
        perpage,
        keytype,
        keyword,
        rescueStyle,
        createStart,
        createEnd,
        yuyueStart,
        yuyueEnd,
        city,
        saleman,
        tech,
        platshow,
        isOpen,
        isShare
      },
    })
    .then((res) => res.data);
};

//订单详情
export const OrderDetail = (rescueId) => {
  return http
    .get("/order/detail", {
      params: {
        rescueId,
      },
    })
    .then((res) => res.data);
};

// 工作台订单异常
// /order/order-exception
export const workUnusual = (type = 0) => {
  return http1
    .get("/order/order-exception-new", {
      params: {
        type,
      },
    })
    .then((res) => res.data);
};
// 新工作台订单异常
export const newworkUnusual = (type) => {
  return http1
    .get("/order/order-exception-new-x-x", {
      params: {
        type,
      },
    })
    .then((res) => res.data);
};

// 地址输入自动补全
// /sys/place-suggestion
export const workMap = (val) => {
  return http1
    .get("/sys/place-suggestion", {
      params: {
        query: val,
      },
    })
    .then((res) => res.data);
};

// 创建订单 合作商列表及对应的
// /sys/place-suggestion
export const getHzlist = () => {
  return http
    .get("/order/gethzlist", {
      params: {},
    })
    .then((res) => res.data);
};

export const getservicelist = (belongCid) => {
  return http
    .get("/order/get-rescue-list", {
      params: {
        belongCid: belongCid,
      },
    })
    .then((res) => res.data);
};

export const getpricelist = (belongCid, rescueType) => {
  return http
    .get("/order/get-price-list", {
      params: {
        belongCid: belongCid,
        rescueType: rescueType,
      },
    })
    .then((res) => res.data);
};

export const createOrder = (
  belongCid,
  rescueStyle,
  appointmentTime,
  rescueType,
  rescueName,
  rescueMobile,
  rescueCarNumber,
  address,
  addressLnglat,
  destination,
  destinationLnglat,
  isGua,
  guaKm,
  remark,
  ticketId,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  bridgestyle,
  name1,
  tel1,
  name2,
  tel2,
  givename,
  givemobile,
  is_entrust,
  isrescue,
  cartypename,
  needcar,
  rescue_time,
) => {
  return http
    .post("/order/do-create", {
      belongCid: belongCid,
      rescueStyle: rescueStyle,
      appointmentTime: appointmentTime,
      rescueType: rescueType,
      rescueName: rescueName,
      rescueMobile: rescueMobile,
      rescueCarNumber: rescueCarNumber,
      address: address,
      addressLnglat: addressLnglat,
      destination: destination,
      destinationLnglat: destinationLnglat,
      isGua: isGua,
      guaKm: guaKm,
      remark: remark,
      ticketId: ticketId,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      bridgeStyle: bridgestyle,
      name1: name1,
      tel1: tel1,
      name2: name2,
      tel2: tel2,
      givename: givename,
      givemobile: givemobile,
      is_entrust: is_entrust,
      isrescue: isrescue,
      cartype: cartypename,
      needcar: needcar,
      rescue_time:rescue_time,
    })
    .then((res) => res.data);
};


export const createOrderDupt = (
  belongCid,
  rescueStyle,
  appointmentTime,
  rescueType,
  rescueName,
  rescueMobile,
  rescueCarNumber,
  address,
  addressLnglat,
  destination,
  destinationLnglat,
  isGua,
  guaKm,
  remark,
  ticketId,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  bridgestyle,
  name1,
  tel1,
  name2,
  tel2,
  givename,
  givemobile,
  is_entrust,
  isrescue,
  cartypename,
  needcar,
  rescue_time,
  orderNum,
) => {
  return http1
    .post("/order/do-create-new", {
      belongCid: belongCid,
      rescueStyle: rescueStyle,
      appointmentTime: appointmentTime,
      rescueType: rescueType,
      rescueName: rescueName,
      rescueMobile: rescueMobile,
      rescueCarNumber: rescueCarNumber,
      address: address,
      addressLnglat: addressLnglat,
      destination: destination,
      destinationLnglat: destinationLnglat,
      isGua: isGua,
      guaKm: guaKm,
      remark: remark,
      ticketId: ticketId,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      bridgeStyle: bridgestyle,
      name1: name1,
      tel1: tel1,
      name2: name2,
      tel2: tel2,
      givename: givename,
      givemobile: givemobile,
      is_entrust: is_entrust,
      isrescue: isrescue,
      cartype: cartypename,
      needcar: needcar,
      rescue_time:rescue_time,
      orderNum:orderNum
    })
    .then((res) => res.data);
};


export const createOrderDuptCar = (
  belongCid,
  rescueStyle,
  appointmentTime,
  rescueType,
  rescueName,
  rescueMobile,
  rescueCarNumber,
  address,
  addressLnglat,
  destination,
  destinationLnglat,
  isGua,
  guaKm,
  remark,
  ticketId,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  bridgestyle,
  name1,
  tel1,
  name2,
  tel2,
  givename,
  givemobile,
  is_entrust,
  isrescue,
  cartypename,
  needcar,
  rescue_time,
  otherNumber,
) => {
  return http1
    .post("/order/do-create-new-car", {
      belongCid: belongCid,
      rescueStyle: rescueStyle,
      appointmentTime: appointmentTime,
      rescueType: rescueType,
      rescueName: rescueName,
      rescueMobile: rescueMobile,
      rescueCarNumber: rescueCarNumber,
      address: address,
      addressLnglat: addressLnglat,
      destination: destination,
      destinationLnglat: destinationLnglat,
      isGua: isGua,
      guaKm: guaKm,
      remark: remark,
      ticketId: ticketId,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      bridgeStyle: bridgestyle,
      name1: name1,
      tel1: tel1,
      name2: name2,
      tel2: tel2,
      givename: givename,
      givemobile: givemobile,
      is_entrust: is_entrust,
      isrescue: isrescue,
      cartype: cartypename,
      needcar: needcar,
      rescue_time:rescue_time,
      otherNumber:otherNumber
    })
    .then((res) => res.data);
};


export const createOrderCopy = (
  belongCid,
  rescueStyle,
  appointmentTime,
  rescueType,
  rescueName,
  rescueMobile,
  rescueCarNumber,
  address,
  addressLnglat,
  destination,
  destinationLnglat,
  isGua,
  guaKm,
  remark,
  ticketId,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  bridgestyle,
  name1,
  tel1,
  name2,
  tel2,
  givename,
  givemobile,
  is_entrust,
  isrescue,
  cartypename,
  needcar,
  rescue_time,
  copy_id
) => {
  return http
    .post("/order/do-create", {
      belongCid: belongCid,
      rescueStyle: rescueStyle,
      appointmentTime: appointmentTime,
      rescueType: rescueType,
      rescueName: rescueName,
      rescueMobile: rescueMobile,
      rescueCarNumber: rescueCarNumber,
      address: address,
      addressLnglat: addressLnglat,
      destination: destination,
      destinationLnglat: destinationLnglat,
      isGua: isGua,
      guaKm: guaKm,
      remark: remark,
      ticketId: ticketId,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      bridgeStyle: bridgestyle,
      name1: name1,
      tel1: tel1,
      name2: name2,
      tel2: tel2,
      givename: givename,
      givemobile: givemobile,
      is_entrust: is_entrust,
      isrescue: isrescue,
      cartype: cartypename,
      needcar: needcar,
      rescue_time:rescue_time,
      copy_id:copy_id
    })
    .then((res) => res.data);
};


export const createOrderCopyDupt = (
  belongCid,
  rescueStyle,
  appointmentTime,
  rescueType,
  rescueName,
  rescueMobile,
  rescueCarNumber,
  address,
  addressLnglat,
  destination,
  destinationLnglat,
  isGua,
  guaKm,
  remark,
  ticketId,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  bridgestyle,
  name1,
  tel1,
  name2,
  tel2,
  givename,
  givemobile,
  is_entrust,
  isrescue,
  cartypename,
  needcar,
  rescue_time,
  copy_id,
  orderNum
) => {
  return http
    .post("/order/do-create-new", {
      belongCid: belongCid,
      rescueStyle: rescueStyle,
      appointmentTime: appointmentTime,
      rescueType: rescueType,
      rescueName: rescueName,
      rescueMobile: rescueMobile,
      rescueCarNumber: rescueCarNumber,
      address: address,
      addressLnglat: addressLnglat,
      destination: destination,
      destinationLnglat: destinationLnglat,
      isGua: isGua,
      guaKm: guaKm,
      remark: remark,
      ticketId: ticketId,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      bridgeStyle: bridgestyle,
      name1: name1,
      tel1: tel1,
      name2: name2,
      tel2: tel2,
      givename: givename,
      givemobile: givemobile,
      is_entrust: is_entrust,
      isrescue: isrescue,
      cartype: cartypename,
      needcar: needcar,
      rescue_time:rescue_time,
      copy_id:copy_id,
      orderNum:orderNum
    })
    .then((res) => res.data);
};


export const createOrderCopyDuptCar = (
  belongCid,
  rescueStyle,
  appointmentTime,
  rescueType,
  rescueName,
  rescueMobile,
  rescueCarNumber,
  address,
  addressLnglat,
  destination,
  destinationLnglat,
  isGua,
  guaKm,
  remark,
  ticketId,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  bridgestyle,
  name1,
  tel1,
  name2,
  tel2,
  givename,
  givemobile,
  is_entrust,
  isrescue,
  cartypename,
  needcar,
  rescue_time,
  copy_id,
  otherNumber
) => {
  return http
    .post("/order/do-create-new-car", {
      belongCid: belongCid,
      rescueStyle: rescueStyle,
      appointmentTime: appointmentTime,
      rescueType: rescueType,
      rescueName: rescueName,
      rescueMobile: rescueMobile,
      rescueCarNumber: rescueCarNumber,
      address: address,
      addressLnglat: addressLnglat,
      destination: destination,
      destinationLnglat: destinationLnglat,
      isGua: isGua,
      guaKm: guaKm,
      remark: remark,
      ticketId: ticketId,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      bridgeStyle: bridgestyle,
      name1: name1,
      tel1: tel1,
      name2: name2,
      tel2: tel2,
      givename: givename,
      givemobile: givemobile,
      is_entrust: is_entrust,
      isrescue: isrescue,
      cartype: cartypename,
      needcar: needcar,
      rescue_time:rescue_time,
      copy_id:copy_id,
      otherNumber:otherNumber
    })
    .then((res) => res.data);
};

export const editOrder = (
  rescueId,
  belongCid,
  rescueStyle,
  appointmentTime,
  rescueType,
  rescueName,
  rescueMobile,
  rescueCarNumber,
  address,
  addressLnglat,
  destination,
  destinationLnglat,
  isGua,
  guaKm,
  //remark,
  ticketId,
  chargeType,
  guidePrice,
  guideKm,
  kmPrice,
  bridgestyle,
  name1,
  tel1,
  name2,
  tel2,
  givename,
  givemobile,
  is_entrust,
  isrescue,
  cartypename,
  needcar,
  rescue_time,
) => {
  return http
    .post("/order/do-edit", {
      rescueId: rescueId,
      belongCid: belongCid,
      rescueStyle: rescueStyle,
      appointmentTime: appointmentTime,
      rescueType: rescueType,
      rescueName: rescueName,
      rescueMobile: rescueMobile,
      rescueCarNumber: rescueCarNumber,
      address: address,
      addressLnglat: addressLnglat,
      destination: destination,
      destinationLnglat: destinationLnglat,
      isGua: isGua,
      guaKm: guaKm,
      //remark: remark,
      ticketId: ticketId,
      chargeType: chargeType,
      guidePrice: guidePrice,
      guideKm: guideKm,
      kmPrice: kmPrice,
      bridgestyle: bridgestyle,
      name1: name1,
      tel1: tel1,
      name2: name2,
      tel2: tel2,
      givename: givename,
      givemobile: givemobile,
      is_entrust: is_entrust,
      isrescue: isrescue,
      cartype: cartypename,
      needcar: needcar,
      rescue_time:rescue_time,
    })
    .then((res) => res.data);
};

// shouli
export const accept = (id) => {
  return http
    .post("/order/do-accept", {
      rescueId: id,
    })
    .then((res) => res.data);
};

export const messageaccept = (id) => {
  return http
    .post("/order/do-message-accept", {
      id: id,
    })
    .then((res) => res.data);
};


export const messageread = (id) => {
  return http
    .post("/sys/message-read", {
      id: id,
    })
    .then((res) => res.data);
};


// 铃铛消息数量
//  /sys/has-message  get
export const messageNum = () => {
  return http1
    .get("/sys/has-message", {
      params: {},
    })
    .then((res) => res.data);
};

// 铃铛消息列表
// /sys/message-list    get
export const messageList = (type = "1", page = 1, perpage = "5") => {
  return http1
    .get("/sys/message-list", {
      params: {
        type,
        page,
        perpage,
      },
    })
    .then((res) => res.data);
};

export const getsetlist = () => {
  return http.get("/sys/get-set-rescue-list", {}).then((res) => res.data);
};

export const getauthkey = () => {
  return http.get("/sys/get-auth", {}).then((res) => res.data);
};

export const addrescuedefined = (id, name) => {
  return http
    .post("/sys/add-rescue-defined", {
      id: id,
      name: name,
    })
    .then((res) => res.data);
};

export const getshowmould = (id) => {
  return http
    .get("/sys/show-mould", {
      params: {
        id: id,
      },
    })
    .then((res) => res.data);
};

export const updatemould = (id, md1, md2, md3) => {
  return http
    .post("/sys/update-mould", {
      id: id,
      mould1: md1,
      mould2: md2,
      mould3: md3,
    })
    .then((res) => res.data);
};

export const getdefinedname = (id) => {
  return http
    .post("/sys/get-defined-name", {
      id: id,
    })
    .then((res) => res.data);
};

export const updaterescuedefined = (id, name) => {
  return http
    .post("/sys/update-rescue-defined", {
      id: id,
      name: name,
    })
    .then((res) => res.data);
};

export const defineddostart = (id) => {
  return http
    .post("/sys/dostart", {
      id: id,
    })
    .then((res) => res.data);
};

export const ordertotal = () => {
  return http
    .get("/order/order-total", {
      params: {},
    })
    .then((res) => res.data);
};

export const getcity = () => {
  return http
    .get("/sys/get-city", {
      params: {},
    })
    .then((res) => res.data);
};

export const defineddostop = (id) => {
  return http
    .post("/sys/dostop", {
      id: id,
    })
    .then((res) => res.data);
};

export const feedback = (title, content) => {
  return http
    .post("/sys/feedback", {
      title: title,
      content: content,
    })
    .then((res) => res.data);
};

export const userinfo = () => {
  return http.get("/sys/userinfo", {}).then((res) => res.data);
};

export const updateuserinfo = (name, tel) => {
  return http
    .post("/sys/update-user", {
      name: name,
      tel: tel,
    })
    .then((res) => res.data);
};

export const updatepassword = (password, passwordrepeat) => {
  return http
    .post("/sys/update-password", {
      password: password,
      passwordrepeat: passwordrepeat,
    })
    .then((res) => res.data);
};

export const getCodeUrl = () => {
  return http1.get("/sys/get-code-url", {}).then((res) => res.data);
};

export const getCodeKjUrl = () => {
  return http1.get("/sys/get-code-kj-url", {}).then((res) => res.data);
};

export const getState = (state) => {
  return http1
    .get("/sys/get-state", {
      params: {
        state: state,
      },
    })
    .then((res) => res.data);
};

export const getTixian = () => {
  return http1
    .get("/sys/get-sp-auth", {
      params: {},
    })
    .then((res) => res.data);
};

export const getTixianAccount = () => {
  return http1
    .get("/sys/get-sp-account", {
      params: {},
    })
    .then((res) => res.data);
};

export const tixianSubmit = (money,pic) => {
  return http
    .post("/sys/tixian-submit", {
      money: money,
      pic:pic,
    })
    .then((res) => res.data);
};

export const getMap = (type, guishu, keyword, servicestatus) => {
  return http3
    .get("/sys/get-map", {
      params: {
        type: type,
        guishu: guishu,
        keyword: keyword,
        servicestatus: servicestatus,
      },
    })
    .then((res) => res.data);
};

export const getMapNew = (type, guishu, keyword, servicestatus) => {
  return http1
    .get("/sys/get-map-new", {
      params: {
        type: type,
        guishu: guishu,
        keyword: keyword,
        servicestatus: servicestatus,
      },
    })
    .then((res) => res.data);
};

export const getMapDetail = (uid) => {
  return http3
    .get("/sys/get-map-detail", {
      params: {
        uid: uid,
      },
    })
    .then((res) => res.data);
};

//获取认证信息
export const getAuthInfo = () => {
  return http1
    .get("/sys/get-auth-info", { params: {} })
    .then((res) => res.data);
};

export const uploadauth = (
  sp_name,
  name,
  idcard,
  open_name,
  bank_num,
  idcarda,
  idcardb,
  license_pic,
  bank_pic
) => {
  return http
    .post("/sys/upload-auth", {
      sp_name: sp_name,
      name: name,
      idcard: idcard,
      open_name: open_name,
      bank_num: bank_num,
      idcarda: idcarda,
      idcardb: idcardb,
      license_pic,
      license_pic,
      bank_pic,
      bank_pic,
    })
    .then((res) => res.data);
};

export const getservicelistedit = (belongCid, rescuetype) => {
  return http
    .get("/order/get-rescue-list-edit", {
      params: {
        belongCid: belongCid,
        rescuetype: rescuetype,
      },
    })
    .then((res) => res.data);
};

export const getaddress = (lnglat) => {
  return http
    .get("/sys/get-address", {
      params: {
        lnglat: lnglat,
      },
    })
    .then((res) => res.data);
};

export const getOtherEmic = (extn) => {
  return http
    .get("/sys/get-user", {
      params: {
        extn: extn,
      },
    })
    .then((res) => res.data);
};

export const getOtherEmicNew = (extn) => {
  return http
    .get("/sys/get-user-new", {
      params: {
        extn: extn,
      },
    })
    .then((res) => res.data);
};

export const getcartype = () => {
  return http2.post("/admin/api/get-rescued-price", {}).then((res) => res.data);
};

export const getcartypenew = (cid) => {
  return http.post("/sys/get-rescued-price", {cid:cid}).then((res) => res.data);
};

export const getcompanytype = () => {
  return http.get("/sys/get-company-type", {}).then((res) => res.data);
};

export const getcompanytypea = () => {
  return http.get("/sys/get-company-type-a", {}).then((res) => res.data);
};

export const getsaleslist = () => {
  return http.get("/sys/get-sales-list", {}).then((res) => res.data);
};

export const getgrouplist = () => {
  return http.get("/sys/get-group-list", {}).then((res) => res.data);
};

export const getsaleslista = () => {
  return http.get("/sys/get-sales-list-a", {}).then((res) => res.data);
};

export const getphoto = (cid) => {
  return http
    .get("/sys/get-photo-list", {
      params: {
        cid: cid,
      },
    })
    .then((res) => res.data);
};

export const getshowmouldx = (id, cid) => {
  return http
    .get("/sys/show-mould-x", {
      params: {
        id: id,
        cid: cid,
      },
    })
    .then((res) => res.data);
};

export const updatemouldx = (id, md1, md2, md3, cid) => {
  return http
    .post("/sys/update-mould-x", {
      id: id,
      mould1: md1,
      mould2: md2,
      mould3: md3,
      cid: cid,
    })
    .then((res) => res.data);
};

export const savelog = (x) => {
  return http
    .post("/sys/save-log", {
      x: x,
    })
    .then((res) => res.data);
};


export const savelogX = (x) => {
  return http
    .post("/sys/save-log-more", {
      x: x,
    })
    .then((res) => res.data);
};

export const getcalllog = () => {
  return http
    .get("/sys/get-emic-log", {
      params: {},
    })
    .then((res) => res.data);
};


export const gettelname = (tel) => {
  return http
    .get("/sys/get-name", {
      params: {
        tel:tel
      },
    })
    .then((res) => res.data);
};

export const apimessage = (id) => {
  return http
    .post("/sys/read-api-message", {
      id: id,
    })
    .then((res) => res.data);
};

export const apimessageNew = (id) => {
  return http
    .post("/sys/message-read-new", {
      id: id,
    })
    .then((res) => res.data);
};

export const gettechlist = (type) => {
  return http
    .get("/sys/get-tech-list", {
      params: {
        type: type,
      },
    })
    .then((res) => res.data);
};

export const getkefulist = () => {
  return http.get("/sys/get-kefu-list", {}).then((res) => res.data);
};

export const getdiaodulist = () => {
  return http.get("/sys/get-diaodu-list", {}).then((res) => res.data);
};

// 新增预设线路
export const added = (data) => {
  let {
    cid,
    address,
    address_lnglat,
    destination,
    destination_lnglat,
    price,
    price2,
    price3,
  } = data;
  return http
    .post("/service-provider/add-line", {
      cid,
      address,
      address_lnglat,
      destination,
      destination_lnglat,
      price,
      price2,
      price3,
    })
    .then((res) => res.data);
};

// 规划公里
export const guihua = (address_lnglat, destination_lnglat) => {
  return http
    .post("/service-provider/plan-km", {
      address_lnglat,
      destination_lnglat,
    })
    .then((res) => res.data);
};

// 修改预设线路
export const xiugai = (data) => {
  let {
    cid,
    address,
    address_lnglat,
    destination,
    destination_lnglat,
    price,
    price2,
    price3,
    id,
  } = data;
  return http
    .post("/service-provider/update-line", {
      cid,
      address,
      address_lnglat,
      destination,
      destination_lnglat,
      price,
      price2,
      price3,
      id,
    })
    .then((res) => res.data);
};

// 删除预设
export const delyushe = (id, cid) => {
  return http
    .post("/service-provider/delete-line", {
      id,
      cid,
    })
    .then((res) => res.data);
};

// 原始预设线路
export const Original = (cid) => {
  return http
    .get("/service-provider/pre-line", {
      params: {
        cid,
      },
    })
    .then((res) => res.data);
};

// 统计信息
export const Statistics = (type) => {
  return http
    .get("/sys/get-top-new", {
      params: {
        type,
      },
    })
    .then((res) => res.data);
};

//个人数据
export const StatisticsSelf = (type) => {
  return http
    .get("/sys/get-top-new-self", {
      params: {
        type,
      },
    })
    .then((res) => res.data);
};



export const getoplist = () => {
  return http
    .get("/sys/get-op-price-list", {
      params: {
      },
    })
    .then((res) => res.data);
};


export const oplist = () => {
  return http
    .get("/sys/get-rescue-type-list", {
      params: {
      },
    })
    .then((res) => res.data);
};


export const saveopprice = (rescueType,carType,chargeType,guidePrice,guideKm,kmPrice,cancelType,cancelTxt,failType,failTxt) => {
  return http
    .post("/sys/save-op-price", {
      rescueType: rescueType,
      carType:carType,
      chargeType:chargeType,
      guidePrice:guidePrice,
      guideKm:guideKm,
      kmPrice:kmPrice,
      cancelType:cancelType,
      cancelTxt:cancelTxt,
      failType:failType,
      failTxt:failTxt
    })
    .then((res) => res.data);
};

export const oppricedetail = (rescueType,carType) => {
  return http
    .post("/sys/get-op-price-detail", {
      rescueType: rescueType,
      carType:carType
    })
    .then((res) => res.data);
};

export const delopprice = (id) => {
  return http
    .post("/sys/del-op-price", {
      id: id,
    })
    .then((res) => res.data);
};

export const finishedit = (id,distance,price,platprice,srccid,carnumber,opprice) => {
  return http
    .post("/order/do-finish-edit", {
      id: id,
      distance:distance,
      price:price,
      platprice:platprice,
      srccid:srccid,
      carnumber:carnumber,
      opprice:opprice
    })
    .then((res) => res.data);
};



export const techsaveopprice = (uid,rescueType,carType,chargeType,guidePrice,guideKm,kmPrice,cancelType,cancelTxt,failType,failTxt) => {
  return http
    .post("/tech/save-op-price", {
      uid:uid,
      rescueType: rescueType,
      carType:carType,
      chargeType:chargeType,
      guidePrice:guidePrice,
      guideKm:guideKm,
      kmPrice:kmPrice,
      cancelType:cancelType,
      cancelTxt:cancelTxt,
      failType:failType,
      failTxt:failTxt
    })
    .then((res) => res.data);
};


export const gettechopprice = (uid) => {
  return http
  .get("/tech/tech-op-price", {
    params: {
      uid:uid
    },
  })
  .then((res) => res.data);
};

export const deltechprice = (id,uid) => {
  return http
  .get("/tech/del-tech-op-price", {
    params: {
      id:id,
      uid:uid
    },
  })
  .then((res) => res.data);
};


export const techoppricedetail = (rescueType,carType,uid) => {
  return http
    .post("/tech/get-op-price-detail", {
      rescueType: rescueType,
      carType:carType,
      uid:uid
    })
    .then((res) => res.data);
};


export const readocrwarn = (id) => {
  return http
  .post("/order/read-ocr-warning", {
    rescue_id: id,
  })
  .then((res) => res.data);
};


export const automationtake = (id,rescue_id) => {
  return http
  .post("/sys/take-automation", {
      rescue_id: rescue_id,
      id:id
  })
  .then((res) => res.data);
};



export const getCountyList = (city) => {
  return http
  .get("/sys/get-county-list", {
    params: {
      city:city
    },
  })
  .then((res) => res.data);
};


export const getsales = () => {
  return http
    .get("/sys/get-sales ", {
      params: {},
    })
    .then((res) => res.data);
};


export const changeKm = (rescueId,km) => {
  return http
    .post("/order/change-km", {
      rescue_id: rescueId,
      km:km,
    })
    .then((res) => res.data);
};
